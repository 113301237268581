import styles from "./Order.module.scss";
import Header from "../../components/Header/Header";
import selectOpen from "../../assets/selectOpen.png";
import leftArrow from "../../assets/leftArrow02.png";
import bottomArrow from "../../assets/bottomArrow04.png";
import rightArrow from "../../assets/rightArrow06.png";
import countryDrop from "../../assets/countryDrop.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { isMobile, isDesktop } from "react-device-detect";
import closeBtn from "../../assets/closeBtn03.png";
import CouponModal from "../../components/CouponModal/CouponModal";
import axiosClient from "../../libs/axiosClient";
import { IBasketDataType, IBasketProductType } from "../Basket/Basket";
import jwt_decode from "jwt-decode";
import { ITokenInfoType, loginCheck, logout } from "../../auth/auth";
import LoginHandler from "../../components/LoginHandler/LoginHandler";
import naver from "../../assets/pay/naver.png";
import kakao from "../../assets/pay/kakao.png";
import smile from "../../assets/pay/smile.png";
import payco from "../../assets/pay/payco.png";
import apple from "../../assets/pay/apple.png";
import card from "../../assets/pay/card.png";
import phone from "../../assets/pay/phone.png";
import bank from "../../assets/pay/bank.png";
import globalCard from "../../assets/pay/globalCard.png";
import order from "../../assets/question/order.png";
import pay from "../../assets/question/card.png";
import coupon from "../../assets/question/coupon.png";
import payBanner from "../../assets/question/payBanner.png";
import payBanner2 from "../../assets/question/payBanner2.png";
import easyBanner from "../../assets/question/easyBanner.png";
import dietEasyBanner from "../../assets/question/dietEasyBanner.png";
import productDrop from "../../assets/question/productDrop.png";
import productDropRed from "../../assets/question/productDropRed.png";
import rightGrey from "../../assets/question/rightGrey.png";
import mPrev from "../../assets/question/mPrev.png";
import dropGrey from "../../assets/dropGrey.png";
import cardAdd from "../../assets/dietProgram/cardAdd.png";
import master from "../../assets/dietProgram/payIcon/master.png";
import { ICardType } from "../MyCard/MyCard";
import bc from "../../assets/dietProgram/payIcon/bc.png";
import citi from "../../assets/dietProgram/payIcon/citi.png";
import gwangju from "../../assets/dietProgram/payIcon/gwangju.png";
import hana from "../../assets/dietProgram/payIcon/hana.png";
import hyundai from "../../assets/dietProgram/payIcon/hyundai.png";
import jcb from "../../assets/dietProgram/payIcon/jcb.png";
import jeju from "../../assets/dietProgram/payIcon/jeju.png";
import jeonbuk from "../../assets/dietProgram/payIcon/jeonbuk.png";
import k from "../../assets/dietProgram/payIcon/k.png";
import kakaoPay from "../../assets/dietProgram/payIcon/kakao.png";
import kb from "../../assets/dietProgram/payIcon/kb.png";
import kdb from "../../assets/dietProgram/payIcon/kdb.png";
import lotte from "../../assets/dietProgram/payIcon/lotte.png";
import masterPay from "../../assets/dietProgram/payIcon/master.png";
import nh from "../../assets/dietProgram/payIcon/nh.png";
import post from "../../assets/dietProgram/payIcon/post.png";
import sae from "../../assets/dietProgram/payIcon/sae.png";
import samsung from "../../assets/dietProgram/payIcon/samsung.png";
import saving from "../../assets/dietProgram/payIcon/saving.png";
import shinhan from "../../assets/dietProgram/payIcon/shinhan.png";
import shinhyup from "../../assets/dietProgram/payIcon/shinhyup.png";
import toss from "../../assets/dietProgram/payIcon/toss.png";
import visa from "../../assets/dietProgram/payIcon/visa.png";
import woori from "../../assets/dietProgram/payIcon/woori.png";

declare var daum: any;

const countryList = ["미국", "중국", "일본", "캐나다", "호주", "기타"];

const nameRegex = /^[ㄱ-ㅎ가-힣a-zA-Z ]+$/;
const globalNameRegex = /^[a-zA-Z ]+$/;

const emailRegex =
  /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
// const telRegex = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;
const payappTelRegex = /^01[0-9]*$/;
const telRegex = /^[0-9]*$/;
const registrationNumberRegex =
  /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-8][0-9]{6}$/;
export interface ICouponType {
  company_id: null;
  coupon_name: string;
  coupon_target: string;
  created_at: string;
  discount_maximum_price: number;
  discount_type: string;
  discount_value: number;
  expire_date: string;
  id: string;
  is_useable: string;
  min_order_price: number;
  product_id: null;
  status: string;
  type: string;
  used_at: null;
}

export interface ISubListType {
  cycle_expire: number;
  cycle_limit: number;
  cycle_type: string;
  id: number;
  option_id: number;
  option_name: string;
  product_id: number;
  product_name: string;
  status: string;
  subscription_info: ISubInfoType[];
}

export interface ISubInfoType {
  count: number;
  desc: string;
  image: string;
  name: string;
  price: string;
  product_id: string;
  option_id?: number;
}

function Order() {
  const navigate = useNavigate();
  const { cartId } = useParams();
  const { isDirect } = useParams();
  const location = useLocation();
  const isInitial = new URLSearchParams(location.search).get("isInitial");

  const [payStep, setPayStep] = useState(1);
  const [isOrderList, setIsOrderList] = useState(true);
  const [isProductOpenFirst, setIsProductOpenFirst] = useState(true);
  const [isProductOpenSecond, setIsProductOpenSecond] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [needPrescription, setNeedPrescription] = useState(false);
  const [prescriptionOnly, setPrescriptionOnly] = useState(true); //주문상품이 처방의약품 뿐일때 true 건강식품 포함시 false
  const [healthfoodOnly, setHealthFoodOnly] = useState(true); //주문상품이 건강식품 뿐일때 true 처방의약품 포함시 false

  // 방문예약 정보 상태
  const [isReservation, setIsReservation] = useState("N");
  const [reservationAt, setReservationAt] = useState<null | string>(null);
  // 방문예약 정보 상태

  // 주문자 정보 상태
  const [ordererName, setOrdererName] = useState("");
  const [ordererEmail, setOrdererEmail] = useState("");
  const [ordererTel, setOrdererTel] = useState("");
  // 주문자 정보 상태

  // 문진표 정보 상태
  const [ordererSame, setOrdererSame] = useState(true);
  const [patientName, setPatientName] = useState("");
  const [patientTel, setPatientTel] = useState("");
  const [patientSecurityNumber1, setPatientSecurityNumber1] = useState("");
  const [patientSecurityNumber2, setPatientSecurityNumber2] = useState("");
  const [patientHeight, setPatientHeight] = useState("");
  const [patientWeight, setPatientWeight] = useState("");
  const [reason, setReason] = useState("");
  const [pregnancy, setPregnancy] = useState<boolean>();
  const [dailyCoffee, setDailyCoffee] = useState(0); //하루 커피 섭취량
  const [caffeineSensitivity, setCaffeineSensitivity] = useState(0); //카페인 예민도
  const [dietPillsExperience, setDietPillsExperience] = useState([
    false,
    false,
    false,
    false,
  ]); //다이어트약 복용경험
  const [dietPillsLevel, setDietPillsLevel] = useState(0); //약 강도
  const [sideEffects, setSideEffects] = useState("");
  // 문진표 정보 상태

  // 배송지 정보 상태
  const [countryOpen, setCountryOpen] = useState<boolean>(false);
  const [openPostcode, setOpenPostcode] = useState<boolean>(false);
  const [reciptMethod, setReciptMethod] = useState(0);
  const [deliveryMethod, setDeliveryMethod] = useState(0);
  const [snsIdMethod, setSnsIdMethod] = useState("kakaotalk");
  const [deliveryType, setDeliveryType] = useState(-1);
  let [deliveryNation, setDeliveryNation] =
    useState("배송받을 국가를 선택해주세요");
  const [deliveryEtcNation, setDeliveryEtcNation] = useState("");
  const [deliverySnsId, setDeliverySnsId] = useState("");
  const [globalDeliveryName, setGlobalDeliveryName] = useState("");
  const [globalDeliveryTel, setGlobalDeliveryTel] = useState("");
  const [globalZipCode, setGlobalZipCode] = useState("");
  const [globalDeliveryAddress, setGlobalDeliveryAddress] = useState("");
  const [globalDeliveryAddressDetail, setGlobalDeliveryAddressDetail] =
    useState("");
  let [deliveryName, setDeliveryName] = useState("");
  let [deliveryTel, setDeliveryTel] = useState("");
  let [zipCode, setZipCode] = useState("");
  let [deliveryAddress, setDeliveryAddress] = useState("");
  let [deliveryAddressDetail, setDeliveryAddressDetail] = useState("");
  const [deliveryMemo, setDeliveryMemo] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (loginCheck()) setIsLogin(true);
    else setIsLogin(false);
  }, []);

  useEffect(() => {
    if (reciptMethod == 1) {
      setZipCode("");
      setDeliveryAddress("");
      setDeliveryAddressDetail("");
    }
  }, [reciptMethod]);

  useEffect(() => {
    if (deliveryNation !== "기타") setDeliveryEtcNation("");
  }, [deliveryNation]);

  function handleSearchAddressOpen() {
    if (isMobile) setOpenPostcode(true);
    else {
      new daum.Postcode({
        oncomplete: function (data: any) {
          setZipCode(data.zonecode);
          setDeliveryAddress(data.address);
        },
      }).open({ popupKey: "pcPostPopup1" });
    }
  }
  useEffect(() => {
    if (openPostcode) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [openPostcode]);
  const handle = {
    // 버튼 클릭 이벤트
    clickButton: () => {
      setOpenPostcode((current) => !current);
    },

    // 주소 선택 이벤트
    selectAddress: (data: any) => {
      setZipCode(data.zonecode);
      setDeliveryAddress(data.address);
      setOpenPostcode(false);
    },
  };
  // 배송지 정보 상태

  // 진료 방법 상태
  const [treatmentMethod, setTreatmentMethod] = useState(0);

  // 할인혜택 정보 상태
  const [discountInfoOpen, setDiscountInfoOpen] = useState(false);
  const [userPoint, setUserPoint] = useState(0);
  const [point, setPoint] = useState<number>(0);
  const [usePoint, setUsePoint] = useState(0);
  function pointApply() {
    if (!orderData) return;
    if (
      healthfoodOnly &&
      point >
        orderData.sort_by_category.health_functional_discount_price_sum +
          orderData.total_delivery_sum
    ) {
      setPoint(0);
      return alert("포인트는 건강식품 금액까지만 사용 가능합니다.");
    }
    if (
      basicSet &&
      payMethod === 3 &&
      point >
        orderData.sort_by_category.health_functional_discount_price_sum +
          orderData.total_delivery_sum
    ) {
      setPoint(0);
      return alert("포인트는 건강식품 금액까지만 사용 가능합니다.");
    }
    if (
      isDiet &&
      payMethod === 3 &&
      point >
        orderData.sort_by_category.health_functional_discount_price_sum +
          orderData.total_delivery_sum
    ) {
      setPoint(0);
      return alert("포인트는 제약 공정비 금액까지만 사용 가능합니다.");
    }
    if (userPoint < 1000) {
      alert("포인트는 1,000원 이상부터 사용 가능합니다.");
      return;
    }
    if (point % 1000 !== 0) {
      alert("포인트는 1,000원 단위로 사용 가능합니다.");
      return;
    }
    let confirm = window.confirm("포인트를 적용하시겠습니까?");
    if (confirm) setUsePoint(point);
  }

  const [promotion, setPromotion] = useState("");
  const [promotionPrice, setPromotionPrice] = useState(0);
  const [promotionPercent, setPromotionPercent] = useState(0);
  function checkPromotion() {
    axiosClient
      .get(`/data/promotion/check-code?promotion_code=${promotion}`)
      .then((res) => {
        if (res.data.result == "Fail") {
          alert("유효하지 않은 프로모션입니다.");
          return;
        }
        let usePromotion = window.confirm("프로모션을 적용하시겠습니까?");
        if (usePromotion) {
          if (res.data.data.discount_type == "price") {
            setPromotionPrice(res.data.data.discount_value);
            setPromotionPercent(0);
          } else if (res.data.data.discount_type == "per") {
            setPromotionPercent(res.data.data.discount_value);
            setPromotionPrice(0);
          }
        } else {
          setPromotionPrice(0);
          setPromotionPercent(0);
        }
      });
  }
  // 할인혜택 정보 상태

  // 결제수단 상태
  const [cardList, setCardList] = useState<ICardType[]>([]);
  const [easyCardId, setEasyCardId] = useState("");
  const [easyCard, setEasyCard] = useState(0);
  const [payMethod, setPayMethod] = useState(0);
  const [withoutBankbook, setWithoutBankbook] = useState(false); // 무통장입금 결제 가능시 true
  const [withoutBankbookList, setwithoutBankbookList] = useState<
    {
      bank_account_holder: string;
      bank_id: number;
      bank_name: string;
      bank_num: string;
    }[]
  >([]); //무통장입금 계좌번호리스트
  const [selectedBankId, setSelectedBankId] = useState<number>();

  useEffect(() => {
    if (payMethod !== 3) setEasyCard(0);
    if (payMethod === 3 && easyCard === 0) {
      setEasyCard(1);
      if (cardList.length) setEasyCardId(cardList[0].id);
    }

    if (!healthfoodOnly) {
      setPoint(0);
      setUsePoint(0);
      setPromotion("");
      setPromotionPrice(0);
      setPromotionPercent(0);
      setUseCoupon(undefined);
    }
  }, [payMethod]);

  useEffect(() => {
    axiosClient
      .get("/data/billkey", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.error_message) return;
        if (
          res.data.data &&
          res.data.data !== "등록된 결제수단이 존재하지 않습니다."
        )
          setCardList(res.data.data);
      });
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let check_can_paymethod_result = await axiosClient
          .get("/data/order/check_can_paymethod")
          .then((res) => res.data.data.paymethod);
        if (check_can_paymethod_result.includes("online")) {
          setWithoutBankbook(true);
          await getBankCodes();
          await getDepositBanks();
        }
        await getPointData();
      } catch (err) {
        alert(
          "에러가 발생했습니다. 지속적으로 에러 발생시 사이트 우측 하단 채널톡을 통해 문의바랍니다."
        );
        navigate("/");
      }
    })();
  }, []);
  async function getBankCodes() {
    //환불계좌 등록 가능 계좌리스트
    await axiosClient.get("/data/bank_codes").then((res) => {
      setRefundBankList(res.data.data);
    });
  }
  async function getDepositBanks() {
    //무통장입금 가능 계좌 리스트
    await axiosClient.get("/data/order/deposit_banks").then((res) => {
      setwithoutBankbookList(res.data.data.banks);
      setSelectedBankId(res.data.data.banks[0].bank_id);
    });
  }

  async function getPointData() {
    if (loginCheck())
      await axiosClient.get("/data/point").then((res) => {
        setUserPoint(res.data.data.total_point);
      });
  }
  // useEffect(() => {
  //   if (!withoutBankbook) return;
  //   axiosClient.get('/data/order/deposit_banks').then((res) => {
  //     setwithoutBankbookList(res.data.data.banks);
  //     setSelectedBankId(res.data.data.banks[0].bank_id);
  //   });
  // }, [withoutBankbook]);
  const [depositorName, setDepositorName] = useState("");
  // 결제수단 상태

  //환불계좌 관련 상태
  const [accountHolder, setAccountHolder] = useState("");
  const [refundBank, setRefundBank] = useState("");
  const [refundBankList, setRefundBankList] = useState<Object>();
  const [refundAccountNumber, setRefundAccountNumber] = useState("");
  // useEffect(() => {
  //   if (!withoutBankbook) return;
  //   axiosClient.get('/data/bank_codes').then((res) => {
  //     setRefundBankList(res.data.data);
  //   });
  // }, [withoutBankbook]);
  //환불계좌 관련 상태

  // 약관동의 관련 상태
  const [payAgree, setPayAgree] = useState(false);
  const [privacyAgree, setPrivacyAgree] = useState(false);
  const [diagnosisAgree, setDiagnosisAgree] = useState(false);
  const [payAgreeOpen, setPayAgreeOpen] = useState(false);
  const [privacyAgreeOpen, setPrivacyAgreeOpen] = useState(false);
  const [diagnosisAgreeOpen, setDiagnosisAgreeOpen] = useState(false);
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const [agreementModalType, setAgreementModalType] = useState(-1);

  // 구매상태
  const [isBuy, setIsBuy] = useState(false);
  useEffect(() => {
    if (payMethod === 3 && easyCard === 0) setIsBuy(false);
    else {
      if (payAgree && privacyAgree && diagnosisAgree) {
        if (isSubscribe && !cardList.length) setIsBuy(false);
        else setIsBuy(true);
      } else setIsBuy(false);
    }
  }, [payAgree, privacyAgree, diagnosisAgree, payMethod, easyCard]);

  useEffect(() => {
    if (agreementModalOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [agreementModalOpen]);
  // 약관동의 관련 상태
  // 쿠폰 관련 상태
  const [couponModalOpen, setCouponModalOpen] = useState(false);
  const [couponList, setCouponList] = useState<ICouponType[]>([]);
  const [useCoupon, setUseCoupon] = useState<ICouponType>();
  useEffect(() => {
    if (couponModalOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [couponModalOpen]);
  // 쿠폰 관련 상태

  //포인트, 쿠폰, 프로모션
  const [totalDiscountPrice, setTotalDiscountPrice] = useState(0);
  useEffect(() => {
    //%할인 쿠폰이나 %할인 프로모션 사용시 최종 금액에서 쿠폰 할인 적용 => 쿠폰 할인 적용된 금액에서 다시 프로모션 쿠폰 금액 적용
    if (!orderData) return;
    let couponPrice = useCoupon
      ? useCoupon.discount_type == "price" //쿠폰 타입이 price이면
        ? useCoupon.discount_value // 단순 쿠폰 할인 금액을 추가하고,
        : useCoupon.discount_type == "per" // 쿠폰 타입이 per이면
        ? Math.floor(
            orderData.total_discount_price_sum *
              (useCoupon.discount_value / 100) //쿠폰 할인 %계산해서 추가
          )
        : 0
      : 0;
    let promotion =
      promotionPrice !== 0
        ? promotionPrice
        : promotionPercent !== 0
        ? Math.floor(
            (orderData.total_discount_price_sum - couponPrice) *
              (promotionPercent / 100)
          )
        : 0;

    setTotalDiscountPrice(usePoint + promotion + couponPrice);
  }, [usePoint, promotionPrice, promotionPercent, useCoupon]);

  useEffect(() => {
    if (!orderData) return;
    if (
      totalDiscountPrice >
      orderData.sort_by_category.health_functional_discount_price_sum +
        orderData.total_delivery_sum
    ) {
      alert(`할인 금액이 ${isDiet ? "제약 공정비" : "건강식품"}보다 큽니다.`);
      setPoint(0);
      setUsePoint(0);
      setPromotion("");
      setPromotionPrice(0);
      setPromotionPercent(0);
      setUseCoupon(undefined);
    }
  }, [totalDiscountPrice]);

  useEffect(() => {
    if (ordererSame) {
      setPatientName(ordererName);
      setPatientTel(ordererTel);
    } else {
      setPatientName("");
      setPatientTel("");
    }
  }, [ordererSame]);
  useEffect(() => {
    if (deliveryType == 0) {
      setDeliveryName(ordererName);
      setDeliveryTel(ordererTel);
    } else if (deliveryType == 1) {
      setDeliveryName("");
      setDeliveryTel("");
      setZipCode("");
      setDeliveryAddress("");
      setDeliveryAddressDetail("");
    }
  }, [deliveryType]);

  //스크롤 위치에 따른 구매하기 버튼 fixed처리
  const buyBtnRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState(true);
  useEffect(() => {
    if (!buyBtnRef.current) return;
    const io = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting || isDesktop) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    });
    io.observe(buyBtnRef.current);
    return () => io.disconnect();
  }, [isFixed]);
  //스크롤 위치에 따른 구매하기 버튼 fixed처리

  const [validation, setValidation] = useState({
    isReservation: false,
    reservationAt: false,
    ordererName: false,
    ordererEmail: false,
    ordererTel: false,
    patientName: false,
    patientTel: false,
    patientSecurityNumber: false,
    patientHeight: false,
    patientWeight: false,
    reason: false,
    pregnancy: false,
    dailyCoffee: false,
    caffeineSensitivity: false,
    dietPillsExperience: false,
    dietPillsLevel: false,
    sideEffects: false,
    disease: false,
    medicine: false,
    deliveryNation: false,
    deliveryEtcNation: false,
    deliverySnsId: false,
    deliveryName: false,
    deliveryTel: false,
    zipCode: false,
    deliveryAddress: false,
    deliveryAddressDetail: false,
    payAgree: false,
    privacyAgree: false,
    diagnosisAgree: false,
    selectedBankId: false, //payMethod가 무통장입금이면 은행 선택여부 체크
    depositorName: false, //무통장입금시 입금자명 기입 여부 체크
    accountHolder: false, //환불계좌 예금주 체크
    refundBank: false, //환불계좌 은행 선택 체크
    refundAccountNumber: false, //환불계좌 계좌번호 입력 체크
  });
  function checkValidate() {
    let validate = {
      isReservation: false,
      reservationAt: false,
      ordererName: false,
      ordererEmail: false,
      ordererTel: false,
      patientName: false,
      patientTel: false,
      patientSecurityNumber: false,
      patientHeight: false,
      patientWeight: false,
      reason: false,
      pregnancy: false,
      dailyCoffee: false,
      caffeineSensitivity: false,
      dietPillsExperience: false,
      dietPillsLevel: false,
      sideEffects: false,
      disease: false,
      medicine: false,
      deliveryNation: false,
      deliveryEtcNation: false,
      deliverySnsId: false,
      deliveryName: false,
      deliveryTel: false,
      zipCode: false,
      deliveryAddress: false,
      deliveryAddressDetail: false,
      payAgree: false,
      privacyAgree: false,
      diagnosisAgree: false,
      selectedBankId: false,
      depositorName: false,
      accountHolder: false, //환불계좌 예금주 체크
      refundBank: false, //환불계좌 은행 선택 체크
      refundAccountNumber: false, //환불계좌 계좌번호 입력 체크
    };
    if (isInitial) {
      if (!isReservation || !reservationAt) validate.reservationAt = true;
    }

    //문진표 정보 검증
    if (needPrescription && !isSubscribe) {
      if (patientName.length == 0) validate.patientName = true;
      if (
        // !telRegex.test(patientTel)
        patientTel.length < 8
      )
        validate.patientTel = true;
      if (
        // !registrationNumberRegex.test(
        //   patientSecurityNumber1 + '-' + patientSecurityNumber2,
        // )
        patientSecurityNumber1.length !== 6 ||
        patientSecurityNumber2.length !== 7
      )
        validate.patientSecurityNumber = true;
      if (!patientHeight.length) validate.patientHeight = true;
      if (!patientWeight.length) validate.patientWeight = true;
      if (!reason.length) validate.reason = true;
      if (pregnancy == undefined) validate.pregnancy = true;
      if (dailyCoffee == 0) validate.dailyCoffee = true;
      if (caffeineSensitivity == 0) validate.caffeineSensitivity = true;
      if (!dietPillsExperience.includes(true))
        validate.dietPillsExperience = true;
      if (
        !dietPillsExperience[0] &&
        !(
          dietPillsExperience[3] === true &&
          dietPillsExperience
            .slice(0, 3)
            .every((item: boolean) => item === false)
        )
      ) {
        if (dietPillsLevel == 0) validate.dietPillsLevel = true;
        if (sideEffects.length == 0) validate.sideEffects = true;
      }
      //질병 여부 검증
      if (disease == undefined) validate.disease = true;
      //질병 여부 검증
      //복용중인 약 검증
      if (medicine == undefined) validate.medicine = true;
      //복용중인 약 검증
    }

    //약관 동의 검증
    if (!payAgree) validate.payAgree = true;
    if (!privacyAgree) validate.privacyAgree = true;
    if (!diagnosisAgree) validate.diagnosisAgree = true;

    setValidation({ ...validate });
    console.log(validation);
    if (Object.values(validate).includes(true)) {
      if (!payAgree || !privacyAgree || !diagnosisAgree)
        alert("필수 약관에 모두 동의해주세요.");
      else alert("필수 입력 정보를 올바르게 작성해주세요.");
      return false;
    } else return true;
  }

  function getOrderProductName() {
    if (!orderData) {
      return;
    }
    let productName = orderData.carts[0].cart[0].product.title;
    let count = 0;
    orderData.carts.map((company) =>
      company.cart.map((product) => {
        count++;
      })
    );
    if (count !== 1) productName += ` 외 ${count - 1}건`;
    return productName;
  }
  /*
   *  연동방식별 파라미터 세팅 ('결제하기' 버튼 클릭시 호출)
   */
  // 결제창 넘어가기 전에 입력 정보 저장(뒤로가기, 결제 취소 대비)
  function saveInfo() {
    const infomation: any = {};
    infomation.ordererName = ordererName;
    infomation.ordererEmail = ordererEmail;
    infomation.ordererTel = ordererTel;
    infomation.patientName = patientName;
    infomation.patientTel = patientTel;
    infomation.patientSecurityNumber1 = patientSecurityNumber1;
    infomation.patientSecurityNumber2 = patientSecurityNumber2;
    infomation.patientHeight = patientHeight;
    infomation.patientWeight = patientWeight;
    infomation.caffeineSensitivity = caffeineSensitivity;
    infomation.dailyCoffee = dailyCoffee;
    infomation.dietPillsExperience = dietPillsExperience;
    infomation.dietPillsLevel = dietPillsLevel;
    infomation.sideEffects = sideEffects;
    infomation.reason = reason;
    infomation.pregnancy = pregnancy;
    infomation.disease = disease;
    infomation.diseaseDetail = diseaseDetail;
    infomation.medicine = medicine;
    infomation.medicineDetail = medicineDetail;
    infomation.deliveryMethod = deliveryMethod;
    infomation.deliveryNation = deliveryNation;
    infomation.snsIdMethod = snsIdMethod;
    infomation.deliverySnsId = deliverySnsId;
    infomation.deliveryName = deliveryName;
    infomation.deliveryTel = deliveryTel;
    infomation.zipCode = zipCode;
    infomation.deliveryAddress = deliveryAddress;
    infomation.deliveryAddressDetail = deliveryAddressDetail;
    infomation.deliveryMemo = deliveryMemo;
    infomation.globalDeliveryName = globalDeliveryName;
    infomation.globalDeliveryTel = globalDeliveryTel;
    infomation.globalZipCode = globalZipCode;
    infomation.globalDeliveryAddress = globalDeliveryAddress;
    infomation.globalDeliveryAddressDetail = globalDeliveryAddressDetail;
    infomation.payMethod = payMethod;
    // infomation.totalDiscountPrice = totalDiscountPrice;
    // infomation.promotion = promotion;
    // infomation.point = point;
    // infomation.usePoint = usePoint;
    // infomation.useCoupon = useCoupon;

    sessionStorage.setItem("temporaryOrderInfo", JSON.stringify(infomation));
    sessionStorage.setItem("lastOrderPageUrl", location.pathname);
  }
  // 결제창 넘어가기 전에 입력 정보 저장(뒤로가기, 결제 취소 대비)
  const handleClick = async () => {
    if (!orderData) return;
    let formData = new FormData();
    // if (isInitial) {
    //   formData.append('is_reservation', 'Y');
    //   formData.append('reservation_at', reservationAt!);
    // } else formData.append('is_reservation', 'N');

    if (deliveryMethod === 0) deliveryNation = "한국";
    if (deliveryMethod === 1) {
      deliveryName = globalDeliveryName;
      deliveryTel = globalDeliveryTel;
      zipCode = globalZipCode;
      deliveryAddress = globalDeliveryAddress;
      deliveryAddressDetail = globalDeliveryAddressDetail;
    }

    // if (treatmentMethod === 0) formData.append('is_reservation', 'N');
    // else formData.append('is_reservation', 'Y');
    formData.append("is_reservation", "N");

    orderData?.carts.map((company) => {
      company.cart.map((product) => {
        product.items.map((option) => {
          formData.append("cart_ids", String(option.id));
        });
      });
    });
    formData.append("order_name", ordererName);
    formData.append("order_email", ordererEmail);
    formData.append("order_phone", ordererTel);
    formData.append("is_pickup", reciptMethod == 1 ? "Y" : "N");
    formData.append(
      "country_name",
      deliveryNation === "기타" ? deliveryEtcNation : deliveryNation
    );
    formData.append(
      "messanger",
      deliveryMethod === 1 &&
        (snsIdMethod === "kakaotalk" || snsIdMethod === "line")
        ? `${snsIdMethod}__${deliverySnsId}`
        : ""
    );
    formData.append("address_name", deliveryName);
    formData.append("address_phone", deliveryTel);
    formData.append("address_zipcode", zipCode);
    formData.append(
      "address_address",
      deliveryMethod === 0 ? deliveryAddress : globalDeliveryAddress
    );
    formData.append(
      "address_address_add",
      deliveryMethod === 0 ? deliveryAddressDetail : globalDeliveryAddressDetail
    );
    formData.append("request_memo", deliveryMemo);
    formData.append("is_direct", isDirect!);

    const amount =
      healthfoodOnly || isSubscribe
        ? String(
            orderData?.sort_by_category.health_functional_discount_price_sum
          )
        : payMethod === 3
        ? String(orderData.total_discount_price_sum)
        : String(orderData?.sort_by_category.telemedicine_discount_price_sum);
    // const amount =
    //   healthfoodOnly || isSubscribe
    //     ? String(
    //         orderData?.sort_by_category.health_functional_discount_price_sum,
    //       )
    //     : String(orderData?.sort_by_category.telemedicine_discount_price_sum);

    const delivery =
      healthfoodOnly || isSubscribe
        ? String(orderData?.total_delivery_sum)
        : payMethod === 3
        ? String(orderData?.total_delivery_sum)
        : "0";

    formData.append("amount", amount);
    // formData.append('amount', String(orderData?.total_discount_price_sum));
    formData.append("delivery", delivery);
    formData.append("use_point", String(usePoint));
    let couponTotalPrice = useCoupon
      ? useCoupon.discount_type == "price" //쿠폰 타입이 price이면
        ? useCoupon.discount_value // 단순 쿠폰 할인 금액을 추가하고,
        : useCoupon.discount_type == "per" // 쿠폰 타입이 per이면
        ? Math.floor(
            orderData.total_discount_price_sum *
              (useCoupon.discount_value / 100) //쿠폰 할인 %계산해서 추가
          )
        : 0
      : 0;
    let totalPromotionPrice =
      promotionPrice !== 0
        ? promotionPrice
        : promotionPercent !== 0
        ? Math.floor(
            (orderData.total_discount_price_sum - couponTotalPrice) *
              (promotionPercent / 100)
          )
        : 0;
    if (useCoupon) {
      formData.append("use_coupon", String(useCoupon.id));
      formData.append("use_coupon_price", String(couponTotalPrice));
    }
    if (promotionPrice !== 0 || promotionPercent !== 0) {
      formData.append("use_promotion_code", promotion);
      formData.append("use_promotion_price", String(totalPromotionPrice));
    }

    const totalAmount =
      healthfoodOnly || isSubscribe
        ? String(
            orderData?.sort_by_category.health_functional_price_sum +
              orderData?.total_delivery_sum -
              totalDiscountPrice
          )
        : payMethod === 3
        ? String(
            orderData.total_discount_price_sum +
              orderData?.total_delivery_sum -
              totalDiscountPrice
          )
        : !isProgram && prescriptionOnly
        ? String(
            orderData.sort_by_category.telemedicine_discount_price_sum +
              orderData?.total_delivery_sum -
              totalDiscountPrice
          )
        : String(
            orderData &&
              orderData?.sort_by_category.telemedicine_price_sum + 0 - 0
            // orderData?.sort_by_category.telemedicine_price_sum +
            //   0 -
            //   totalDiscountPrice,
          );

    // const totalAmount =
    //   healthfoodOnly || isSubscribe
    //     ? String(
    //         orderData?.sort_by_category.health_functional_price_sum +
    //           orderData?.total_delivery_sum -
    //           totalDiscountPrice,
    //       )
    //     : String(
    //         orderData &&
    //           orderData?.sort_by_category.telemedicine_price_sum + 0 - 0,
    //       );

    formData.append("total_amount_sum", totalAmount);
    // formData.append(
    //   'pay_method',
    //   payMethod == 0
    //     ? 'payple_app'
    //     : payMethod == 1
    //     ? 'payple'
    //     : payMethod == 2
    //     ? 'payple_global'
    //     : payMethod == 3
    //     ? 'online'
    //     : '',
    // );
    // if (payMethod == 3) {
    //   formData.append('bank_info', String(selectedBankId));
    //   formData.append('depositor_name', depositorName);
    //   formData.append('moneyback_bank', refundBank);
    //   formData.append('moneyback_account', refundAccountNumber);
    //   formData.append('moneyback_owner_name', accountHolder);
    // }
    formData.append(
      "pay_method",
      payMethod === 0 ? "card" : payMethod === 1 ? "foreign" : "billKey"
    );
    if (payMethod === 3) formData.append("bill_key", easyCardId);
    if (needPrescription && !isSubscribe) {
      formData.append("user_name", patientName);
      formData.append(
        "social_number",
        patientSecurityNumber1 + "-" + patientSecurityNumber2
      );
      formData.append("phone", patientTel);
      formData.append("height", patientHeight);
      formData.append("weight", patientWeight);
      formData.append("is_pregnant", pregnancy ? "Y" : "N");
      formData.append("question", reason);
      formData.append("is_disease", disease ? "Y" : "N");
      formData.append("disease", diseaseDetail);
      formData.append("is_taking_medicines", medicine ? "Y" : "N");
      formData.append("taking_medicines", medicineDetail);
      formData.append("daily_coffee_intake", String(dailyCoffee));
      formData.append("caffeine_sensitivity", String(caffeineSensitivity));
      dietPillsExperience.map((val, idx) => {
        if (val) formData.append("diet_pills_experience", String(idx + 1));
      });
      if (
        !dietPillsExperience[0] &&
        !(
          dietPillsExperience[3] === true &&
          dietPillsExperience
            .slice(0, 3)
            .every((item: boolean) => item === false)
        )
      ) {
        formData.append("diet_pills_level", String(dietPillsLevel));
        formData.append("diet_pills_side_effects", String(sideEffects));
      }
    }
    formData.append("is_app", localStorage.getItem("mobileApp") ? "Y" : "");
    formData.append(
      "order_step",
      healthfoodOnly || isSubscribe ? "health_functional" : "telemedicine"
    );

    // let formValues: any = formData.entries();
    // for (const pair of formValues) {
    //   console.log(pair[0] + ':' + pair[1]);
    // }

    let isError = false;

    let createOrderResult = await axiosClient
      .post("/func/combined_order", formData)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        if (err) alert(err.response.data.detail);
        isError = true;
      });
    if (isError) {
      navigate("/basket");
    }

    window.location.href = `${createOrderResult.payurl}`;
    saveInfo();

    // if (payMethod == 3) {
    //   //무통장입금 선택시 페이플 넘어가지 않고 바로 주문완료 페이지 이동
    //   navigate(`/orderComplete/${createOrderResult.order_id}`);
    //   return;
    // }
    // if (payMethod == 0 || payMethod == 1) payplePay(createOrderResult);
    // else if (payMethod == 2) {
    //   globalPayplePay(createOrderResult);
    // }
  };
  // function payplePay(data: any) {
  //   // 페이플 결제 파라미터 정보 설정
  //   const obj: any = {};
  //   obj.PCD_PAY_TYPE = 'card';
  //   obj.PCD_PAY_WORK = 'CERT';
  //   obj.PCD_CARD_VER = payMethod == 0 ? '02' : '01';
  //   obj.PCD_SIMPLE_FLAG = payMethod == 0 ? 'N' : 'Y';
  //   obj.PCD_AUTH_KEY = data.PCD_AUTH_KEY;
  //   obj.PCD_PAY_URL = data.PCD_PAY_URL;
  //   if (localStorage.getItem('mobileApp')) obj.PCD_APP_SCHEME = 'ondocapp://';
  //   if (data.PCD_PAYER_ID !== '') obj.PCD_PAYER_ID = data.PCD_PAYER_ID;
  //   if (payMethod == 0) {
  //     //일반결제시
  //   } else if (payMethod == 1) {
  //     //간편결제시
  //     obj.PCD_PAYER_AUTHTYPE = 'pwd';
  //   }
  //   obj.PCD_PAYER_NO = '';
  //   obj.PCD_PAY_OID = data.order_id; // 주문번호
  //   obj.PCD_PAYER_NAME = ordererName; // 주문자명
  //   obj.PCD_PAYER_HP = ordererTel; // 주문자 핸드폰
  //   obj.PCD_PAYER_EMAIL = ordererEmail; // 주문자 이메일
  //   obj.PCD_PAY_GOODS = getOrderProductName(); // 상품명
  //   obj.PCD_PAY_TOTAL =
  //     orderData!.total_discount_price_sum +
  //     orderData!.total_delivery_sum -
  //     totalDiscountPrice; // 결제금액
  //   obj.PCD_PAY_ISTAX = '';
  //   saveInfo();
  //   obj.PCD_RST_URL = 'https://api.ondoc.co.kr/func/order/order-result-get';
  //   (window as any).PaypleCpayAuthCheck(obj);
  // }
  // function globalPayplePay(data: any) {
  //   let obj: any = new Object();
  //   obj.Authorization = data.access_token;
  //   obj.service_id = 'ondoc1';
  //   obj.service_oid = data.order_id;
  //   obj.comments = getOrderProductName();
  //   obj.totalAmount = Math.ceil(
  //     (orderData!.total_discount_price_sum +
  //       orderData!.total_delivery_sum -
  //       totalDiscountPrice) /
  //       data.exchange_rate,
  //   );
  //   obj.currency = 'USD';
  //   obj.email = ordererEmail;
  //   obj.resultUrl = `https://api.ondoc.co.kr/func/order/order-result-payple/${data.order_id}`;
  //   obj.isDirect = 'Y';
  //   obj.serviceDefine = String(
  //     orderData!.total_discount_price_sum +
  //       orderData!.total_delivery_sum -
  //       totalDiscountPrice,
  //   );
  //   saveInfo();
  //   setIsSubmitting(false);
  //   (window as any).paypleGpayPaymentRequest(obj);
  // }

  // 구매 버튼 눌렀을 때
  async function handleSubmit() {
    // navigate(`/order-continue/${cartId}`);
    if (!isBuy) return;
    if (isSubmitting) return;
    setIsSubmitting(true);
    const isOk = checkValidate();
    if (!isOk) {
      setIsSubmitting(false);
      return;
    }
    await handleClick();
    setIsSubmitting(false);
  }

  // 질환, 약 여부 체크
  const [disease, setDisease] = useState<boolean>();
  const [medicine, setMedicine] = useState<boolean>();
  const [diseaseDetail, setDiseaseDetail] = useState("");
  const [medicineDetail, setMedicineDetail] = useState("");

  const [orderCount, setOrderCount] = useState<any>();
  const [orderData, setOrderData] = useState<IBasketDataType>();
  const [orderDataPresc, setOrderDataPresc] = useState<any[]>([]);
  const [orderDataHealth, setOrderDataHealth] = useState<any[]>([]);

  useEffect(() => {
    if (!cartId || !isDirect) return;
    let itemIdList: number[] = JSON.parse(cartId);
    let queryString = `is_direct=${isDirect}`;
    itemIdList.map((id) => {
      queryString += `&cart_ids=${id}`;
    });
    queryString += "&loc=order";
    axiosClient.get(`/data/cart?${queryString}`).then((res) => {
      if (res.data.data.carts.length == 0) {
        alert("장바구니에 상품이 존재하지 않습니다.");
        navigate("/basket");
      }
      setOrderCount(res.data.total_count);
      setOrderData(res.data.data);

      try {
        for (let i = 0; i < res.data.data.carts.length; i++) {
          let needPrescription = false;
          for (let j = 0; j < res.data.data.carts[i].cart.length; j++) {
            if (res.data.data.carts[i].cart[j].product.product_id == 31)
              setWithoutBankbook(true); //product_id가 31인 제품 구매시엔 무통장 가능하게끔
            if (res.data.data.carts[i].cart[j].product.is_prescription == 1) {
              //처방전 필요한 주문건인지 체크
              needPrescription = true;
              setOrderDataPresc((prev) => [
                ...prev,
                res.data.data.carts[i].cart[j],
              ]);
            } else {
              setOrderDataHealth((prev) => [
                ...prev,
                res.data.data.carts[i].cart[j],
              ]);
            }
          }

          if (needPrescription) {
            setNeedPrescription(true);
            // break;
          }
        }
      } catch (err: any) {
        alert(err.response.data.detail);
      }
    });
  }, []);

  const [subList, setSubList] = useState<ISubInfoType[]>([]);
  const [subCount, setSubCount] = useState([]);

  // 다이어트상품 + 구독 상품
  const [isProgram, setIsProgram] = useState(false);
  // 다이어트상품인지
  const [isDiet, setIsDiet] = useState(false);
  // 구독상품인지
  const [isSubscribe, setIsSubscribe] = useState(false);
  // 일반 처방상품
  const [basicPrescription, setBasicPrescription] = useState(false);
  // 일반 처방+건강 세트
  const [basicSet, setBasicSet] = useState(false);

  useEffect(() => {
    if (!orderData) return;
    if (!isProgram) return;

    // 상품별 count 계산
    const counts: any = [];
    orderData.carts[0].cart[0].items.forEach((option) => {
      counts.push(option.count);
    });
    console.log(counts);
    setSubCount(counts);

    // option_id 배열로 보내는 경우
    // const optionId = orderData.carts[0].cart[0].items.reduce(
    //   (acc, option, index) => {
    //     const prefix = index === 0 ? '?' : '&';
    //     return acc + `${prefix}option_id=${option.option_id}`;
    //   },
    //   '',
    // );

    // axiosClient
    //   .get(`/data/subscription_product${optionId}`)
    //   .then((res) => {
    //     console.log(res.data.data);
    //     setSubList(res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    const getSubscriptionList = async () => {
      const optionItems = orderData.carts[0].cart[0].items;
      let allData: any[] = [];

      for (const item of optionItems) {
        const { option_id, count } = item;
        try {
          const response = await axiosClient.get(
            `/data/subscription_product?option_id=${option_id}`
          );

          // 각 항목에 option_id와 count를 추가
          const plusData = response.data.data.subscription_info.map(
            (product: any) => ({
              ...product,
              option_id,
              count,
            })
          );
          allData = [...allData, ...plusData];
        } catch (err) {
          console.log(err);
        }
      }
      console.log(allData);
      setSubList(allData);
    };

    // 옵션 상세 리스트 조회
    getSubscriptionList();
  }, [orderData, isProgram]);

  useEffect(() => {
    if (!orderData) return;
    if (orderData?.carts[0].cart[0].product.is_subscription !== "Y") return;
    setIsProgram(true);

    // 처방 0원이면 구독상품
    if (orderData.sort_by_category.telemedicine_discount_price_sum === 0) {
      setIsDiet(false);
      setIsSubscribe(true);
    } else {
      setIsDiet(true);
      setIsSubscribe(false);
    }
  }, [orderData]);

  useEffect(() => {
    if (!isProgram && prescriptionOnly) setBasicPrescription(true);
    else setBasicPrescription(false);
  }, [isProgram, prescriptionOnly]);

  useEffect(() => {
    if (!isProgram && !healthfoodOnly && !prescriptionOnly) setBasicSet(true);
    else setBasicSet(false);
  }, [isProgram, healthfoodOnly]);

  useEffect(() => {
    if (healthfoodOnly || isSubscribe || (!isProgram && prescriptionOnly))
      setIsProductOpenFirst(true);
    else setIsProductOpenFirst(false);
  }, [healthfoodOnly, isSubscribe, prescriptionOnly, isProgram]);

  useEffect(() => {
    if (cardList.length) {
      setPayMethod(3);
      setEasyCard(1);
      setEasyCardId(cardList[0].id);
    }
    if (isSubscribe && !cardList.length) {
      setPayMethod(3);
    }
    if (!isSubscribe || !cardList.length) return;
    setPayMethod(3);
    if (cardList.length) {
      setEasyCard(1);
      setEasyCardId(cardList[0].id);
    }
  }, [isSubscribe, cardList]);

  //쿠폰 데이터 받아오고, 상품들의 처방의약품, 건강식품 여부 체크하는 로직
  useEffect(() => {
    if (!orderData) return;
    let list: number[] = [];
    orderData.carts.map((carts) => {
      carts.cart.map((cart) => {
        if (cart.product.is_prescription == 0) setPrescriptionOnly(false);
        else setHealthFoodOnly(false);
        if (
          cart.product.is_prescription == 0 &&
          !cart.product.title.includes("슬림푸시")
        ) {
          cart.items.map((item) => {
            list.push(item.id);
          });
        }
      });
    });
    if (!list.length) return;
    let queryString = ``;
    list.map((id) => {
      queryString += `cart_ids=${id}&`;
    });
    if (loginCheck())
      axiosClient.get(`/data/coupon/useable?${queryString}`).then((res) => {
        setCouponList(res.data.data.coupons);
      });
  }, [orderData]);
  //쿠폰 데이터 받아오는 부분

  function productOptionCal(product: IBasketProductType) {
    let data = { price: 0, count: 0 };
    product.items.map((option) => {
      data.price += option.discount_price * option.count;
      data.count += option.count;
    });
    return data;
  }

  function programOptionCal(product: ISubInfoType) {
    let data = { price: 0, count: 0 };
    data.price += Number(product.price) * product.count;
    data.count += product.count;
    return data;
  }

  useEffect(() => {
    if (loginCheck()) {
      const token = localStorage.getItem("token");
      if (!token || token == null || token == undefined || token == "null")
        return;
      try {
        const userInfo: ITokenInfoType = jwt_decode(token!);
        if (nameRegex.test(userInfo.name)) {
          setOrdererName(userInfo.name);
          setDeliveryName(userInfo.name);
        }
        setOrdererTel(userInfo.phone.replace(/[-)+]/g, ""));
        setDeliveryTel(userInfo.phone.replace(/[-)+]/g, ""));
        setOrdererEmail(userInfo.email);
      } catch (err) {
        logout();
      }
    }

    try {
      const temporaryOrderInfo = JSON.parse(
        sessionStorage.getItem("temporaryOrderInfo") || JSON.stringify("")
      ); //결제 페이지 넘어가기 전 저장해둔 작성 정보, 결제 페이지에서 뒤로가기를 눌러 돌아왔을 때 저장된 정보 가져와서 다시 입력해줌
      const patientState = location.state; //문진표 페이지에서 넘어온 state
      console.log(patientState);
      if (patientState) {
        setIsReservation(patientState.isReservation);
        setReservationAt(patientState.reservationAt);
        setPatientName(patientState.patientName);
        setPatientTel(patientState.patientTel);
        setPatientSecurityNumber1(patientState.patientSecurityNumber1);
        setPatientSecurityNumber2(patientState.patientSecurityNumber2);
        setPatientHeight(patientState.patientHeight);
        setPatientWeight(patientState.patientWeight);
        setReason(patientState.reason);
        setPregnancy(patientState.pregnancy);
        setDailyCoffee(patientState.dailyCoffee);
        setCaffeineSensitivity(patientState.caffeineSensitivity);
        setDietPillsExperience(patientState.dietPillsExperience);
        setDietPillsLevel(patientState.dietPillsLevel);
        setSideEffects(patientState.sideEffects || "");
        setDisease(patientState.disease);
        setDiseaseDetail(patientState.diseaseDetail);
        setMedicine(patientState.medicine);
        setMedicineDetail(patientState.medicineDetail);
        setOrdererName(patientState.ordererName);
        setOrdererEmail(patientState.ordererEmail);
        setOrdererTel(patientState.ordererTel);
        setDeliveryMethod(patientState.deliveryMethod);
        setDeliveryName(patientState.deliveryName);
        setDeliveryTel(patientState.deliveryTel);
        setZipCode(patientState.zipCode);
        setDeliveryAddress(patientState.deliveryAddress);
        setDeliveryAddressDetail(patientState.deliveryAddressDetail);
        setDeliveryMemo(patientState.deliveryMemo);
        setDeliveryNation(patientState.deliveryNation);
        setDeliveryEtcNation(patientState.deliveryEtcNation);
        setSnsIdMethod(patientState.snsIdMethod);
        setDeliverySnsId(patientState.deliverySnsId);
        setGlobalDeliveryName(patientState.deliveryName);
        setGlobalDeliveryName(patientState.deliveryName);
        setGlobalDeliveryTel(patientState.deliveryTel);
        setGlobalZipCode(patientState.zipCode);
        setGlobalDeliveryAddress(patientState.deliveryAddress);
        setGlobalDeliveryAddressDetail(patientState.deliveryAddressDetail);
        setSnsIdMethod(patientState.snsIdMethod);
        setDeliverySnsId(patientState.deliverySnsId);
      } else if (temporaryOrderInfo) {
        setIsReservation(temporaryOrderInfo.isReservation);
        setReservationAt(temporaryOrderInfo.reservationAt);
        if (nameRegex.test(temporaryOrderInfo.ordererName))
          setOrdererName(temporaryOrderInfo.ordererName);
        setOrdererEmail(temporaryOrderInfo.ordererEmail);
        setOrdererTel(temporaryOrderInfo.ordererTel);
        setPatientName(temporaryOrderInfo.patientName);
        setPatientTel(temporaryOrderInfo.patientTel);
        setPatientSecurityNumber1(temporaryOrderInfo.patientSecurityNumber1);
        setPatientSecurityNumber2(temporaryOrderInfo.patientSecurityNumber2);
        setPatientHeight(temporaryOrderInfo.patientHeight);
        setPatientWeight(temporaryOrderInfo.patientWeight);
        setReason(temporaryOrderInfo.reason);
        setPregnancy(temporaryOrderInfo.pregnancy);
        setCaffeineSensitivity(temporaryOrderInfo.caffeineSensitivity);
        setDailyCoffee(temporaryOrderInfo.dailyCoffee);
        setDietPillsExperience(temporaryOrderInfo.dietPillsExperience);
        setDietPillsLevel(temporaryOrderInfo.dietPillsLevel);
        setSideEffects(temporaryOrderInfo.sideEffects);
        setDisease(temporaryOrderInfo.disease);
        setDiseaseDetail(temporaryOrderInfo.diseaseDetail);
        setMedicine(temporaryOrderInfo.medicine);
        setMedicineDetail(temporaryOrderInfo.medicineDetail);
        setMedicineDetail(temporaryOrderInfo.deliveryNation);
        setMedicineDetail(temporaryOrderInfo.deliveryEtcNation);
        setMedicineDetail(temporaryOrderInfo.deliverySnsId);
        if (nameRegex.test(temporaryOrderInfo.deliveryName))
          setDeliveryName(temporaryOrderInfo.deliveryName);
        setDeliveryTel(temporaryOrderInfo.deliveryTel);
        setZipCode(temporaryOrderInfo.zipCode);
        setDeliveryMemo(temporaryOrderInfo.deliveryMemo);
        setPayMethod(temporaryOrderInfo.payMethod);
        setOrdererName(temporaryOrderInfo.ordererName);
        setOrdererEmail(temporaryOrderInfo.ordererEmail);
        setOrdererTel(temporaryOrderInfo.ordererTel);
        setDeliveryMethod(temporaryOrderInfo.deliveryMethod);
        setDeliveryName(temporaryOrderInfo.deliveryName);
        setDeliveryTel(temporaryOrderInfo.deliveryTel);
        setZipCode(temporaryOrderInfo.zipCode);
        setDeliveryAddress(temporaryOrderInfo.deliveryAddress);
        setDeliveryAddressDetail(temporaryOrderInfo.deliveryAddressDetail);
        setGlobalDeliveryAddress(temporaryOrderInfo.globalDeliveryAddress);
        setGlobalDeliveryAddressDetail(
          temporaryOrderInfo.globalDeliveryAddressDetail
        );
        setDeliveryMemo(temporaryOrderInfo.deliveryMemo);
        setDeliveryNation(temporaryOrderInfo.deliveryNation);
        setDeliveryEtcNation(temporaryOrderInfo.deliveryEtcNation);
        setSnsIdMethod(temporaryOrderInfo.snsIdMethod);
        setDeliverySnsId(temporaryOrderInfo.deliverySnsId);
      }
      if (temporaryOrderInfo) {
        setPayAgree(true);
        setPrivacyAgree(true);
        setDiagnosisAgree(true);
        // setTotalDiscountPrice(temporaryOrderInfo.totalDiscountPrice);
        // setPromotion(temporaryOrderInfo.promotion);
        // setPoint(temporaryOrderInfo.point);
        // setUsePoint(temporaryOrderInfo.usePoint);
        // setUseCoupon(temporaryOrderInfo.useCoupon);
      }

      // sessionStorage.removeItem('temporaryOrderInfo');
    } catch (err) {
      sessionStorage.removeItem("temporaryOrderInfo");
      alert("문제가 발생하였습니다. 다시 시도해주세요.");
      navigate("/");
    }
  }, []);

  const getBankImg = (value: string) => {
    if (value.includes("비씨")) return bc;
    if (value.includes("하나")) return hana;
    if (value.includes("NH")) return nh;
    if (value.includes("우리")) return woori;
    if (value.includes("국민")) return kb;

    return "";
  };

  const removeBracket = (value: string) => {
    if (value.startsWith("[") && value.endsWith("]")) {
      return value.slice(1, -1);
    }
    return value;
  };

  const maskNum = (number: string) => {
    const length = number.length;

    if (length <= 4) return number;
    const masked = "*".repeat(length - 4);
    const notMasked = number.slice(-4);
    return masked + notMasked;
  };

  return (
    <div
      className={`${styles.container} ${
        isDesktop ? styles.pcVersion : styles.isMobile
      }`}
    >
      <LoginHandler />
      {isDesktop && <Header />}
      {!isDesktop && (
        <div className={styles.prevWrap}>
          <img onClick={() => navigate(-1)} src={mPrev} alt="mPrev" />
        </div>
      )}
      <div className={styles.stepTitleWrap}>
        <span>결제하기</span>
        {healthfoodOnly || isSubscribe ? (
          <div className={styles.stepWrap}>
            <span>결제하기</span>
            <div></div>
            <div className={styles.hitStep}>2</div>
          </div>
        ) : (
          <div className={styles.stepWrap}>
            <span>결제하기</span>
            <div></div>
            <div></div>
            <div className={styles.hitStep}>3</div>
            {!prescriptionOnly && <div></div>}
          </div>
        )}
      </div>
      <div className={styles.orderForm}>
        <div className={styles.left}>
          <div className={styles.orderTitleWrap}>
            <img src={order} alt="order" />
            <span>나의결제</span>
          </div>
          <div
            className={`${styles.productListWrap} ${
              (healthfoodOnly || isSubscribe) && styles.healthListWrap
            }`}
          >
            {!healthfoodOnly &&
              !isSubscribe &&
              (isProgram || !prescriptionOnly) && (
                <div className={styles.productBannerWrap}>
                  <div className={styles.payBannerWrap}>
                    <img
                      src={
                        isDiet && payMethod === 3
                          ? dietEasyBanner
                          : isDirect && payMethod !== 3
                          ? payBanner2
                          : payMethod === 3
                          ? easyBanner
                          : payBanner
                      }
                      alt="payBanner"
                    />
                  </div>
                  <div
                    className={`${styles.productDropWrap} ${styles.orderDropWrap}`}
                    onClick={() => setIsOrderList((prev) => !prev)}
                  >
                    <div className={styles.productDropTitleWrap}>
                      <div className={styles.categoryWrap}>
                        <span>주문내역</span>
                        <span>{orderCount}건</span>
                      </div>
                      <div className={styles.priceWrap}>
                        <img
                          style={{
                            transform: isOrderList ? "rotate(180deg)" : "none",
                          }}
                          src={dropGrey}
                          alt="dropGrey"
                        />
                      </div>
                    </div>
                    <div className={styles.productDropInfoWrap}>
                      {isOrderList && subList && isDiet ? (
                        subList?.map((product, idx) => (
                          <div className={styles.productInfoWrap} key={idx}>
                            <div className={styles.thumbnailWrap}>
                              <img src={product.image} alt="thumbnail" />
                            </div>
                            <div className={styles.productInfo}>
                              <h3 className={styles.productName}>
                                {product.name}
                              </h3>
                              <ul className={styles.optionList}>
                                {
                                  <li>
                                    <span>
                                      {product.name} {product.desc}
                                    </span>
                                    <span>{product.count}개</span>
                                  </li>
                                }
                              </ul>
                              <div className={styles.totalInfoWrap}>
                                {(() => {
                                  let res = programOptionCal(product);
                                  return (
                                    <>
                                      <span>
                                        {res.price.toLocaleString()}원 / 총 수량{" "}
                                        {res.count}개
                                      </span>
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : !isOrderList && subList && isDiet ? (
                        <div></div>
                      ) : !isOrderList ? (
                        <div></div>
                      ) : (
                        orderData &&
                        orderData?.carts[0].cart.map((product) => (
                          <div
                            className={styles.productInfoWrap}
                            key={product.product.product_id}
                          >
                            <div className={styles.thumbnailWrap}>
                              <img
                                src={product.product.list_image}
                                alt="thumbnail"
                              />
                            </div>
                            <div className={styles.productInfo}>
                              <h3 className={styles.productName}>
                                {product.product.title}
                              </h3>
                              <ul className={styles.optionList}>
                                {product.product.option_depth > 0 &&
                                  product.items.map((option) => (
                                    <li key={option.id}>
                                      <span>
                                        {option.option1 && option.option1}
                                        {option.option2 &&
                                          " / " + option.option2}
                                        {option.option3 &&
                                          " / " + option.option3}
                                      </span>{" "}
                                      <span>{option.count}개</span>
                                    </li>
                                  ))}
                              </ul>
                              <div className={styles.totalInfoWrap}>
                                {(() => {
                                  let res = productOptionCal(product);
                                  return (
                                    <>
                                      <span>
                                        {res.price.toLocaleString()}원 / 총 수량{" "}
                                        {res.count}개
                                      </span>
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                      {isOrderList && (
                        <>
                          <div className={styles.totalPriceWrap}>
                            <div>
                              <span>상품가격</span>
                              <span>
                                ₩{" "}
                                {orderData &&
                                  orderData.total_discount_price_sum.toLocaleString()}
                              </span>
                            </div>
                            <div>
                              <span>할인금액</span>
                              <span>
                                {`₩ ${totalDiscountPrice.toLocaleString()}`}
                              </span>
                            </div>
                            <div>
                              <span>배송비</span>
                              <span>
                                {orderData?.total_delivery_sum === 0
                                  ? "무료"
                                  : `₩ ${orderData?.total_delivery_sum.toLocaleString()}`}
                              </span>
                            </div>
                            <div className={styles.totalWrap}>
                              <span>총 결제 금액</span>
                              <span>
                                {orderData &&
                                  (
                                    orderData.total_discount_price_sum +
                                    orderData?.total_delivery_sum -
                                    totalDiscountPrice
                                  ).toLocaleString()}
                                원
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            <div className={styles.payListWrap}>
              <div
                className={`${styles.productDropWrap} ${
                  payStep === 1 ? styles.hit : ""
                }`}
                onClick={() => {
                  if (
                    !healthfoodOnly &&
                    !isSubscribe &&
                    (isProgram || !prescriptionOnly)
                  )
                    return;
                  setIsProductOpenFirst((prev) => !prev);
                }}
              >
                <div className={styles.productDropTitleWrap}>
                  {!healthfoodOnly &&
                    !isSubscribe &&
                    orderData?.sort_by_category
                      .health_functional_discount_price_sum !== 0 && (
                      <div className={styles.payStepWrap}>첫 번째 결제</div>
                    )}
                  <div className={styles.categoryWrap}>
                    {isSubscribe ? (
                      <span>[정기배송] 디톡스 패키지</span>
                    ) : isDiet ? (
                      <span>
                        {healthfoodOnly ? "제약 공정비" : "처방&약재비"}
                      </span>
                    ) : (
                      <span>{healthfoodOnly ? "건강식품" : "처방의약품"}</span>
                    )}
                    <span>
                      {(() => {
                        if (healthfoodOnly && orderCount)
                          return `${orderCount}건`;
                        if (isSubscribe && orderCount) return `${orderCount}건`;
                        if (
                          !isProgram &&
                          prescriptionOnly &&
                          orderDataPresc.length
                        )
                          return `${orderDataPresc.length}건`;
                        return null;
                      })()}
                      {/* {healthfoodOnly && orderDataHealth.length + '건'}
                      {isSubscribe && orderDataPresc.length + '건'}
                      {!isProgram &&
                        prescriptionOnly &&
                        orderDataPresc.length + '건'} */}
                    </span>
                  </div>
                  <div className={styles.priceWrap}>
                    <span>
                      {/* {(healthfoodOnly || isSubscribe) &&
                        orderData &&
                        orderData?.sort_by_category &&
                        (
                          orderData?.sort_by_category
                            .health_functional_discount_price_sum +
                          orderData?.total_delivery_sum -
                          totalDiscountPrice
                        ).toLocaleString()}
                      {!healthfoodOnly &&
                        orderData &&
                        orderData?.sort_by_category &&
                        (
                          orderData?.sort_by_category
                            .telemedicine_discount_price_sum +
                          0 -
                          0
                        ).toLocaleString()} */}
                      {(healthfoodOnly || isSubscribe) &&
                      orderData &&
                      orderData?.sort_by_category
                        ? (
                            orderData?.sort_by_category
                              .health_functional_discount_price_sum +
                            orderData?.total_delivery_sum -
                            totalDiscountPrice
                          ).toLocaleString()
                        : !isProgram &&
                          prescriptionOnly &&
                          orderData &&
                          orderData?.sort_by_category
                        ? (
                            orderData?.sort_by_category
                              .telemedicine_discount_price_sum +
                            orderData?.total_delivery_sum -
                            totalDiscountPrice
                          ).toLocaleString()
                        : orderData &&
                          orderData?.sort_by_category &&
                          (
                            orderData?.sort_by_category
                              .telemedicine_discount_price_sum +
                            0 -
                            0
                          ).toLocaleString()}
                      원
                    </span>
                    {(healthfoodOnly ||
                      isSubscribe ||
                      (!isProgram && prescriptionOnly)) && (
                      <img
                        style={{
                          transform: isProductOpenFirst
                            ? "rotate(180deg)"
                            : "none",
                        }}
                        src={productDrop}
                        alt="productDrop"
                      />
                    )}
                  </div>
                </div>
                <div className={styles.productDropInfoWrap}>
                  {isProductOpenFirst && !healthfoodOnly && isProgram && subList
                    ? subList?.map((product, idx) => {
                        const prevOptionId = idx > 0 ? subList[idx - 1] : null;
                        const optionId =
                          idx === 0 ||
                          product.option_id !== prevOptionId?.option_id;

                        return (
                          <div
                            className={`${styles.productInfoWrap} ${
                              optionId ? styles.borderProductInfoWrap : ""
                            }`}
                            key={idx}
                          >
                            <div className={styles.thumbnailWrap}>
                              <img src={product.image} alt="thumbnail" />
                            </div>
                            <div className={styles.productInfo}>
                              <h3 className={styles.productName}>
                                {product.name}
                              </h3>
                              <ul className={styles.optionList}>
                                {
                                  <li>
                                    <span>
                                      {product.name} {product.desc}
                                    </span>
                                    <span>{product.count}개</span>
                                  </li>
                                }
                              </ul>
                              <div className={styles.totalInfoWrap}>
                                {(() => {
                                  let res = programOptionCal(product);
                                  return (
                                    <>
                                      <span>
                                        {res.price.toLocaleString()}원 / 총 수량{" "}
                                        {res.count}개
                                      </span>
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : isProductOpenFirst &&
                      !healthfoodOnly &&
                      orderData?.sort_by_category
                    ? orderData?.sort_by_category.telemedicine.map(
                        (product) => (
                          <div
                            className={styles.productInfoWrap}
                            key={product.product.product_id}
                          >
                            <div className={styles.thumbnailWrap}>
                              <img
                                src={product.product.list_image}
                                alt="thumbnail"
                              />
                            </div>
                            <div className={styles.productInfo}>
                              <h3 className={styles.productName}>
                                {product.product.title}
                              </h3>
                              <ul className={styles.optionList}>
                                {product.product.option_depth > 0 &&
                                  product.items.map((option) => (
                                    <li key={option.id}>
                                      <span>
                                        {option.option1 && option.option1}
                                        {option.option2 &&
                                          " / " + option.option2}
                                        {option.option3 &&
                                          " / " + option.option3}
                                      </span>{" "}
                                      <span>{option.count}개</span>
                                    </li>
                                  ))}
                              </ul>
                              <div className={styles.totalInfoWrap}>
                                {(() => {
                                  let res = productOptionCal(product);
                                  return (
                                    <>
                                      <span>
                                        {res.price.toLocaleString()}원 / 총 수량{" "}
                                        {res.count}개
                                      </span>
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                        )
                      )
                    : isProductOpenFirst &&
                      healthfoodOnly &&
                      orderData?.sort_by_category &&
                      orderData?.sort_by_category.health_functional.map(
                        (product) => (
                          <div
                            className={styles.productInfoWrap}
                            key={product.product.product_id}
                          >
                            <div className={styles.thumbnailWrap}>
                              <img
                                src={product.product.list_image}
                                alt="thumbnail"
                              />
                            </div>
                            <div className={styles.productInfo}>
                              <h3 className={styles.productName}>
                                {product.product.title}
                              </h3>
                              <ul className={styles.optionList}>
                                {product.product.option_depth > 0 &&
                                  product.items.map((option) => (
                                    <li key={option.id}>
                                      <span>
                                        {option.option1 && option.option1}
                                        {option.option2 &&
                                          " / " + option.option2}
                                        {option.option3 &&
                                          " / " + option.option3}
                                      </span>{" "}
                                      <span> {option.count}개</span>
                                    </li>
                                  ))}
                              </ul>
                              <div className={styles.totalInfoWrap}>
                                {(() => {
                                  let res = productOptionCal(product);
                                  return (
                                    <>
                                      <span>
                                        {res.price.toLocaleString()}원 / 총 수량{" "}
                                        {res.count}개
                                      </span>
                                    </>
                                  );
                                })()}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  {isProductOpenFirst && (
                    <>
                      {healthfoodOnly || isSubscribe || prescriptionOnly ? (
                        <div className={styles.totalPriceWrap}>
                          <div>
                            <span>상품가격</span>
                            {!isProgram && prescriptionOnly ? (
                              <span>
                                {orderData?.sort_by_category &&
                                  `   ₩ ${orderData?.sort_by_category.telemedicine_discount_price_sum.toLocaleString()}`}
                              </span>
                            ) : (
                              <span>
                                {orderData?.sort_by_category &&
                                  `   ₩ ${orderData?.sort_by_category.health_functional_discount_price_sum.toLocaleString()}`}
                              </span>
                            )}
                          </div>
                          <div>
                            <span>할인금액</span>
                            <span>
                              {`₩ ${totalDiscountPrice.toLocaleString()}`}
                            </span>
                          </div>
                          <div>
                            <span>배송비</span>
                            <span>
                              {`₩ ${(
                                orderData?.total_delivery_sum ?? 0
                              ).toLocaleString()}`}
                            </span>
                          </div>
                          <div className={styles.totalWrap}>
                            <span>총 결제 금액</span>
                            {prescriptionOnly ? (
                              <span>
                                {orderData &&
                                  orderData?.sort_by_category &&
                                  (isSubscribe
                                    ? orderData?.sort_by_category
                                        .health_functional_discount_price_sum +
                                      orderData?.total_delivery_sum -
                                      totalDiscountPrice
                                    : orderData?.sort_by_category
                                        .telemedicine_discount_price_sum +
                                      orderData?.total_delivery_sum -
                                      totalDiscountPrice
                                  ).toLocaleString() + "원"}
                              </span>
                            ) : (
                              <span>
                                {orderData &&
                                  orderData?.sort_by_category &&
                                  (
                                    orderData?.sort_by_category
                                      .health_functional_discount_price_sum +
                                    orderData?.total_delivery_sum -
                                    totalDiscountPrice
                                  ).toLocaleString() + "원"}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className={styles.totalPriceWrap}>
                          <div>
                            <span>처방&약재비 가격</span>
                            <span>
                              {orderData?.sort_by_category &&
                                `   ₩ ${orderData?.sort_by_category.telemedicine_discount_price_sum.toLocaleString()}`}
                            </span>
                          </div>
                          <div className={styles.totalWrap}>
                            <span>총 결제 금액</span>
                            <span>
                              {orderData &&
                                orderData?.sort_by_category &&
                                (
                                  orderData?.sort_by_category
                                    .telemedicine_discount_price_sum +
                                  0 -
                                  0
                                ).toLocaleString() + "원"}
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {!healthfoodOnly &&
                !isSubscribe &&
                orderData?.sort_by_category
                  .health_functional_discount_price_sum !== 0 && (
                  <div
                    className={`${styles.productDropWrap} ${
                      payStep === 2 || payMethod === 3 ? styles.hit : ""
                    } `}
                  >
                    <div className={styles.productDropTitleWrap}>
                      <div className={styles.payStepWrap}>두 번째 결제</div>
                      <div className={styles.categoryWrap}>
                        <span>{isDiet ? "제약 공정비" : "건강식품"}</span>
                      </div>
                      <div className={styles.priceWrap}>
                        <span>
                          {orderData &&
                            orderData?.sort_by_category &&
                            (
                              orderData?.sort_by_category
                                .health_functional_discount_price_sum +
                              orderData?.total_delivery_sum -
                              totalDiscountPrice
                            ).toLocaleString()}
                          원
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              {!healthfoodOnly &&
                !isSubscribe &&
                (!prescriptionOnly || (prescriptionOnly && isDiet)) && (
                  <div className={styles.productTotalWrap}>
                    <span>총 합계 금액</span>
                    <span>
                      {orderData &&
                        (
                          orderData.total_discount_price_sum +
                          orderData?.total_delivery_sum -
                          totalDiscountPrice
                        ).toLocaleString()}
                      원
                    </span>
                  </div>
                )}
            </div>
          </div>
          <div className={`${styles.orderTitleWrap} ${styles.couponTitleWrap}`}>
            <div>
              <img src={coupon} alt="coupon" />
              <span>할인혜택</span>
            </div>
            {/* {!isSubscribe && !prescriptionOnly && (
              <span>
                할인혜택은 {isDiet ? '제약 공정비' : '건강식품'}에 적용됩니다.
              </span>
            )} */}
            {isDiet && <span>할인혜택은 제약 공정비에 적용됩니다.</span>}
          </div>
          <div
            className={`${styles.discountInfoWrap} ${
              isSubscribe ? styles.subDiscountInfoWrap : ""
            }`}
          >
            <div className={styles.content}>
              {/* {((!healthfoodOnly && payMethod !== 3 && !isSubscribe) ||
                (prescriptionOnly && !isSubscribe)) && (
                <div className={styles.noDiscountWrap}>
                  <span>{isProgram ? '처방&약재비는 ' : '처방의약품은 '}</span>
                  <span className={styles.hit}>
                    적립금 및 쿠폰 사용이 약사법상 불가능
                  </span>
                  <span>합니다.</span>
                </div>
              )} */}
              {(basicPrescription ||
                (basicSet && payMethod !== 3) ||
                (isDiet && payMethod !== 3)) && (
                <div className={styles.noDiscountWrap}>
                  <span>{isProgram ? "처방&약재비는 " : "처방의약품은 "}</span>
                  <span className={styles.hit}>
                    적립금 및 쿠폰 사용이 약사법상 불가능
                  </span>
                  <span>합니다.</span>
                </div>
              )}
              {isSubscribe && (
                <div className={styles.noDiscountWrap}>
                  <span>구독 상품은 </span>
                  <span className={styles.hit}>적립금 및 쿠폰 사용이 불가</span>
                  <span>합니다.</span>
                </div>
              )}
              {(healthfoodOnly ||
                (basicSet && payMethod === 3) ||
                (isDiet && payMethod === 3)) && (
                <div className={styles.savings}>
                  <div>
                    {/* {userPoint.toLocaleString()}원 */}
                    <span>적립금</span>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder={"보유 " + userPoint.toLocaleString() + "원"}
                      value={point !== 0 ? point : ""}
                      pattern="[0-9]*"
                      onChange={(e) => {
                        // setUsePoint(0);
                        if (isNaN(Number(e.currentTarget.value))) return;
                        if (Number(e.currentTarget.value) < 0) setPoint(0);
                        else if (Number(e.currentTarget.value) > userPoint)
                          setPoint(userPoint);
                        else {
                          setPoint(Number(e.currentTarget.value));
                        }
                      }}
                    />
                    <div onClick={pointApply}>적용</div>
                  </div>
                </div>
              )}
              {(healthfoodOnly ||
                (basicSet && payMethod === 3) ||
                (isDiet && payMethod === 3)) && (
                <div className={styles.promotion}>
                  <div>
                    <span>프로모션 코드</span>
                  </div>
                  <div>
                    <input
                      type="text"
                      placeholder="코드를 입력해주세요."
                      value={promotion}
                      onChange={(e) => {
                        setPromotion(e.currentTarget.value);
                        setPromotionPercent(0);
                        setPromotionPrice(0);
                      }}
                    />
                    <div onClick={checkPromotion}>적용</div>
                  </div>
                </div>
              )}
              {(healthfoodOnly ||
                (basicSet && payMethod === 3) ||
                (isDiet && payMethod === 3)) && (
                <div
                  className={styles.couponBtn}
                  onClick={() => setCouponModalOpen(true)}
                >
                  <div>
                    <span>쿠폰</span>
                  </div>
                  <div>
                    {
                      <span className={useCoupon ? styles.hit : ""}>
                        {!useCoupon &&
                          "보유 " +
                            couponList.filter(
                              (coupon) =>
                                coupon.status == "N" && coupon.is_useable == "Y"
                            ).length +
                            "장"}
                        {useCoupon && useCoupon.coupon_name}
                      </span>
                    }
                    <img src={rightGrey} alt="arrow" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div
            className={`${styles.orderTitleWrap} ${styles.subOrderTitleWrap}`}
          >
            <div className={styles.leftWrap}>
              <img src={pay} alt="pay" />
              <span>결제수단</span>
            </div>
            {isDesktop && isSubscribe && (
              <div className={styles.subTextWrap}>
                <span>구독 상품은 </span>
                <span className={styles.hit}>간편 카드결제만 </span>
                <span>가능합니다.</span>
              </div>
            )}
          </div>
          {isMobile && isSubscribe && (
            <div className={styles.subTextWrap}>
              <span>구독 상품은 </span>
              <span className={styles.hit}>간편 카드결제만 </span>
              <span>가능합니다.</span>
            </div>
          )}
          {isSubscribe && !cardList.length && (
            <div className={styles.cardAddWrap}>
              <div
                className={styles.cardAddBtnWrap}
                onClick={() => {
                  navigate("/card-register", {
                    state: {
                      path: "order",
                    },
                  });
                }}
              >
                <img src={cardAdd} alt="cardAdd" />
                <span>카드 등록하기</span>
              </div>
            </div>
          )}
          {!(isSubscribe && !cardList.length) && (
            <div className={styles.payMethodWrap}>
              <ul className={styles.payMethodList}>
                <li
                  className={`${styles.easyPayment} ${
                    payMethod === 3 ? styles.hit : ""
                  }`}
                  onClick={() => {
                    if (!cardList.length)
                      return alert("등록된 카드가 없습니다.");
                    setPayMethod(3);
                    // setEasyCard(1);
                  }}
                >
                  <div
                    className={`${styles.easyTitleWrap} ${
                      cardList.length ? styles.marginEasyTitleWrap : ""
                    }`}
                  >
                    <div className={styles.leftWrap}>
                      <div className={styles.customCheckBox}></div>
                      <span>간편 카드 결제</span>
                    </div>
                    {!cardList.length && (
                      <span className={styles.noCardWrap}>
                        등록된 카드가 없습니다.
                      </span>
                    )}
                  </div>
                  {cardList &&
                    cardList.map((card, idx) => (
                      <div className={styles.easyCardListWrap}>
                        <div className={styles.easyCardListFrame}>
                          <div className={styles.leftWrap}>
                            {getBankImg(card?.card_name) && (
                              <img
                                src={getBankImg(card?.card_name)}
                                alt="bankLogo"
                              />
                            )}
                            <span>
                              {removeBracket(card?.card_name) +
                                " " +
                                card?.card_number}
                            </span>
                          </div>
                          <div
                            className={`${styles.selectBtnWrap} ${
                              easyCard === idx + 1 ? styles.select : ""
                            }`}
                            onClick={() => {
                              setEasyCard(idx + 1);
                              setEasyCardId(card.id);
                            }}
                          >
                            선택
                          </div>
                        </div>
                      </div>
                    ))}
                </li>
                {!isSubscribe && (
                  <li
                    className={payMethod === 0 ? styles.hit : ""}
                    onClick={() => setPayMethod(0)}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>일반결제</span>
                  </li>
                )}
                {!isSubscribe && (
                  <li
                    className={payMethod === 1 ? styles.hit : ""}
                    onClick={() => setPayMethod(1)}
                  >
                    <div className={styles.customCheckBox}></div>
                    <span>해외카드</span>
                  </li>
                )}
                {payMethod !== 3 && (
                  <p className={styles.payAlert}>다음 결제수단을 지원합니다.</p>
                )}
                {payMethod === 0 && (
                  <div className={styles.payIconWrap}>
                    <div>
                      <img src={naver} alt="pay" />
                      <img src={apple} alt="pay" />
                    </div>
                    <div>
                      <img src={kakao} alt="pay" />
                      <img src={card} alt="pay" />
                    </div>
                    <div>
                      <img src={smile} alt="pay" />
                      <img src={phone} alt="pay" />
                    </div>
                    <div>
                      <img src={payco} alt="pay" />
                      <img src={bank} alt="pay" />
                    </div>
                  </div>
                )}
                {payMethod === 1 && (
                  <div className={styles.payIconWrap2}>
                    <img src={globalCard} alt="pay" />
                  </div>
                )}
              </ul>
            </div>
          )}
          {/* <div className={styles.payMethodWrap}>
            <h3 className={styles.sectionHeader}>결제수단</h3>
            <ul className={styles.payMethodList}>
              <li
                className={payMethod == 0 ? styles.hit : ''}
                onClick={() => setPayMethod(0)}
              >
                <div className={styles.customCheckBox}></div>
                <span>카드결제</span>
              </li>
              <li
                className={payMethod == 1 ? styles.hit : ''}
                onClick={() => setPayMethod(1)}
              >
                <div className={styles.customCheckBox}></div>
                <span>간편결제</span>
              </li>
              <li
                className={payMethod == 2 ? styles.hit : ''}
                onClick={() => setPayMethod(2)}
              >
                <div className={styles.customCheckBox}></div>
                <span>해외카드결제</span>
              </li>
              {withoutBankbook && (
                <li
                  className={payMethod == 3 ? styles.hit : ''}
                  onClick={() => setPayMethod(3)}
                >
                  <div className={styles.customCheckBox}></div>
                  <span>무통장입금</span>
                </li>
              )}
            </ul>
          </div>
          {withoutBankbook && payMethod == 3 && (
            <div className={styles.bankListWrap}>
              <h3 className={styles.sectionHeader}>입금계좌</h3>
              <div
                className={`${styles.bankSelectWrap} ${
                  validation.selectedBankId ? styles.validationError : ''
                }`}
              >
                <select
                  onChange={(e) => {
                    setSelectedBankId(Number(e.currentTarget.value));
                  }}
                >
                  {withoutBankbookList.map((bank) => (
                    <option value={bank.bank_id} key={bank.bank_id}>
                      {bank.bank_name}({bank.bank_num})
                    </option>
                  ))}
                </select>
                <img src={selectOpen} alt="selectOpen" />
              </div>
              <div className={styles.depositorNameWrap}>
                <div>
                  <span>입금자명</span>
                  <span>*</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.depositorName ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    value={depositorName}
                    onChange={(e) => setDepositorName(e.currentTarget.value)}
                  />
                </div>
              </div>
            </div>
          )}
          {withoutBankbook && payMethod == 3 && (
            <div className={styles.refundInfoWrap}>
              <h3 className={styles.sectionHeader}>환불 계좌 등록</h3>
              <div className={styles.accountHolderWrap}>
                <div>
                  <span>예금주</span>
                  <span>*</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.accountHolder ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    value={accountHolder}
                    onChange={(e) => setAccountHolder(e.currentTarget.value)}
                  />
                </div>
              </div>
              <div
                className={`${styles.bankSelectWrap} ${
                  validation.refundBank ? styles.validationError : ''
                }`}
              >
                <select
                  onChange={(e) => {
                    setRefundBank(e.currentTarget.value);
                  }}
                >
                  <option disabled selected>
                    은행선택
                  </option>
                  {refundBankList &&
                    Object.values(refundBankList).map((bank) => (
                      <option value={bank} key={bank}>
                        {bank}
                      </option>
                    ))}
                </select>
                <img src={selectOpen} alt="selectOpen" />
              </div>
              <div className={styles.accountNumberWrap}>
                <div>
                  <span>계좌번호</span>
                  <span>*</span>
                </div>
                <div
                  className={`${styles.inputWrap} ${
                    validation.refundAccountNumber ? styles.validationError : ''
                  }`}
                >
                  <input
                    type="text"
                    value={refundAccountNumber}
                    onChange={(e) => {
                      let value = e.currentTarget.value;
                      if (/^[\d-]{0,}$/.test(value))
                        setRefundAccountNumber(value);
                      else return;
                    }}
                  />
                </div>
              </div>
            </div>
          )} */}
          {!isSubscribe && (
            <ul
              className={`${styles.priceGuideList} ${
                isMobile ? styles.mPriceGuideList : ""
              }`}
            >
              <li>
                · 무이자할부가 적용되지 않은 상품과 무이자할부가 가능한 상품을
                동시에 구매할 경우 전체 주문 상품 금액에 대해 무이자할부가
                적용되지 않습니다. 무이자할부를 원하시는 경우 장바구니에서
                무이자할부 상품만 선택하여 주문하여 주시기 바랍니다.
              </li>
              <li>
                · 최소 결제 가능 금액은 결제금액에서 배송비를 제외한 금액입니다.
              </li>
            </ul>
          )}
          <ul className={styles.agreementList}>
            <li className={styles.allAgreementWrap}>
              <div>
                <div
                  className={`${styles.selectArea} ${styles.allSelectArea}`}
                  onClick={() => {
                    if (payAgree && privacyAgree && diagnosisAgree) {
                      setPayAgree(false);
                      setPrivacyAgree(false);
                      setDiagnosisAgree(false);
                    } else {
                      setPayAgree(true);
                      setPrivacyAgree(true);
                      setDiagnosisAgree(true);
                    }
                  }}
                >
                  <input
                    type="checkbox"
                    checked={payAgree && privacyAgree && diagnosisAgree}
                    onChange={(e) => {}}
                    className={
                      payAgree && privacyAgree && diagnosisAgree
                        ? styles.checked
                        : ""
                    }
                  />
                  <span
                    className={`${styles.allAgreeText} ${
                      payAgree && privacyAgree && diagnosisAgree
                        ? styles.allHitAgree
                        : ""
                    } `}
                  >
                    필수 약관 모두 동의
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div>
                <div
                  className={`${styles.selectArea} ${
                    validation.payAgree && !payAgree
                      ? styles.validationError
                      : ""
                  }`}
                  onClick={() => setPayAgree((prev) => !prev)}
                >
                  <input
                    type="checkbox"
                    checked={payAgree}
                    onChange={(e) => {}}
                    className={payAgree ? styles.checked : ""}
                  />
                  <span>[필수]구매조건 확인 및 결제진행 동의</span>
                </div>
                <span
                  onClick={() => {
                    if (isDesktop) setPayAgreeOpen((prev) => !prev);
                    else {
                      setAgreementModalOpen(true);
                      setAgreementModalType(0);
                    }
                  }}
                >
                  상세보기
                </span>
              </div>
              {payAgreeOpen && (
                <p>
                  전자상거래법8조 2항 사업자와 전자결제업자등은 전자적
                  대금지급이 이루어지는 경우 소비자가 입력한 정보가 소비자의
                  진정 의사 표시에 의한 것인지를 확인함에 있어 주의를 다하여야
                  한다.
                </p>
              )}
            </li>
            <li>
              <div>
                <div
                  className={`${styles.selectArea} ${
                    validation.privacyAgree && !privacyAgree
                      ? styles.validationError
                      : ""
                  }`}
                  onClick={() => setPrivacyAgree((prev) => !prev)}
                >
                  <input
                    type="checkbox"
                    checked={privacyAgree}
                    onChange={(e) => {}}
                    className={privacyAgree ? styles.checked : ""}
                  />
                  <span>[필수]개인정보 수집 및 활용 동의 안내</span>
                  <span className={styles.agreeHit}></span>
                </div>
                <span
                  onClick={() => {
                    if (isDesktop) {
                      setPrivacyAgreeOpen((prev) => !prev);
                    } else {
                      setAgreementModalOpen(true);
                      setAgreementModalType(1);
                    }
                  }}
                >
                  상세보기
                </span>
              </div>
              {privacyAgreeOpen && (
                <p>
                  개인정보 처리 방침에 관한 사항은 홈페이지에서 확인 가능 하시며
                  , 진료에 필요한 최소한의 정보는 동의 여부와 관계없이 수집되며
                  10년 간 보관됨을 안내 드립니다. 지금 작성해주시는 성명,
                  주민등록번호, 주소, 연락처, 진료과목 등의 정보는 진료목적으로
                  이용되며 의료법 제22조 1항 의료법 시행규칙 제14조 규정에 의해
                  동의 유무와 관계없이 수집 및 이용이 가능한 점 안내드립니다.
                </p>
              )}
            </li>
            <li>
              <div>
                <div
                  className={`${styles.selectArea} ${
                    validation.diagnosisAgree && !diagnosisAgree
                      ? styles.validationError
                      : ""
                  }`}
                  onClick={() => setDiagnosisAgree((prev) => !prev)}
                >
                  <input
                    type="checkbox"
                    checked={diagnosisAgree}
                    onChange={(e) => {}}
                    className={diagnosisAgree ? styles.checked : ""}
                  />
                  <span>[필수]비대면 전화 진료 동의 안내</span>
                </div>
                <span
                  onClick={() => {
                    if (isDesktop) setDiagnosisAgreeOpen((prev) => !prev);
                    else {
                      setAgreementModalOpen(true);
                      setAgreementModalType(2);
                    }
                  }}
                >
                  상세보기
                </span>
              </div>
              {diagnosisAgreeOpen && (
                <p>
                  1. 환자는 진료 전 본의의 명의를 정확히 밝히고, 다른 사람의
                  명의로 진료를 받는 등 거짓이나 부정한 방법으로 진료를 받지
                  아니 한다. 2. 환자는 자신의 건강 관련 정보를 의료인에게 정확히
                  알려야 하며, 거짓으로 진료를 볼 경우 발생하는 문제에 대해서는
                  본인이 모든 책임을 진다. 3. 비대면 전화 진료 시, 의료진 판단
                  하에 복양의 안전을 위해 환자의 상태에 따라 처방이 불가 할 수
                  있다. 4. 환자는 약 복용 시 발현, 불편증상 등 이상 증세가 있을
                  경우 대면진료를 받아야 한다. 5. 의료진 판단 하에 대면 진료를
                  요구할 수 있으며, 환자는 특별한 사정이 없는 한 이에 응해야
                  한다. 6. 비대면 전화 진료의 장점과 주의사항에 대해 확인 및
                  이해하였다.
                </p>
              )}
            </li>
          </ul>
          <div className={styles.buyBtnArea}>
            <div
              className={`${styles.buyBtnWrap} ${
                isFixed ? styles.fixedBtn : ""
              }`}
            >
              <div
                className={`${styles.buyBtn} ${isBuy ? "" : styles.noBuyBtn}`}
                onClick={handleSubmit}
              >
                {/* {(healthfoodOnly || isSubscribe) &&
                  orderData &&
                  (
                    orderData?.sort_by_category.health_functional_price_sum +
                    orderData?.total_delivery_sum -
                    totalDiscountPrice
                  ).toLocaleString()}
                {!healthfoodOnly && !isSubscribe && orderData && payMethod === 3
                  ? (
                      orderData.total_discount_price_sum +
                      orderData?.total_delivery_sum -
                      totalDiscountPrice
                    ).toLocaleString()
                  : !healthfoodOnly &&
                    !isSubscribe &&
                    orderData &&
                    (
                      orderData?.sort_by_category.telemedicine_price_sum +
                      0 -
                      0
                    ).toLocaleString()} */}
                {healthfoodOnly || isSubscribe
                  ? orderData &&
                    (
                      orderData?.sort_by_category.health_functional_price_sum +
                      orderData?.total_delivery_sum -
                      totalDiscountPrice
                    ).toLocaleString()
                  : payMethod === 3 && orderData
                  ? (
                      orderData?.total_discount_price_sum +
                      orderData?.total_delivery_sum -
                      totalDiscountPrice
                    ).toLocaleString()
                  : !isProgram &&
                    prescriptionOnly &&
                    orderData &&
                    orderData?.sort_by_category
                  ? (
                      orderData?.sort_by_category
                        .telemedicine_discount_price_sum +
                      orderData?.total_delivery_sum -
                      totalDiscountPrice
                    ).toLocaleString()
                  : orderData &&
                    orderData?.sort_by_category &&
                    (
                      orderData?.sort_by_category
                        .telemedicine_discount_price_sum +
                      0 -
                      0
                    ).toLocaleString()}
                원 결제하기
              </div>
            </div>
          </div>
          <div ref={buyBtnRef}></div>
        </div>
      </div>
      {openPostcode && (
        <div className={styles.addressModalWrap}>
          <div className={styles.addressModal}>
            <div className={styles.modalHeader}>
              <img
                src={closeBtn}
                alt="closeBtn"
                onClick={() => setOpenPostcode(false)}
              />
            </div>
            <DaumPostcode
              onComplete={handle.selectAddress} // 값을 선택할 경우 실행되는 이벤트
              autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
            />
          </div>
        </div>
      )}
      {agreementModalOpen && (
        <div className={styles.agreementModalContainer}>
          <div className={styles.modal}>
            <h3>
              {agreementModalType == 0
                ? "구매조건 확인 및 결제 진행 동의"
                : agreementModalType == 1
                ? "개인정보 수집 및 활용 동의"
                : agreementModalType == 2
                ? "비대면 전화 진료 동의"
                : ""}
            </h3>
            <div className={styles.content}>
              {agreementModalType == 0
                ? "전자상거래법8조 2항 사업자와 전자결제업자등은 전자적 대금지급이 이루어지는 경우 소비자가 입력한 정보가 소비자의 진정 의사 표시에 의한 것인지를 확인함에 있어 주의를 다하여야 한다."
                : agreementModalType == 1
                ? "개인정보 처리 방침에 관한 사항은 홈페이지에서 확인 가능 하시며 , 진료에 필요한 최소한의 정보는 동의 여부와 관계없이 수집되며 10년 간 보관됨을 안내 드립니다. 지금 작성해주시는 성명, 주민등록번호, 주소, 연락처, 진료과목 등의 정보는 진료목적으로 이용되며 의료법 제22조 1항 의료법 시행규칙 제14조 규정에 의해 동의 유무와 관계없이 수집 및 이용이 가능한 점 안내드립니다."
                : agreementModalType == 2
                ? `1. 환자는 진료 전 본의의 명의를 정확히 밝히고, 다른 사람의 명의로 진료를 받는 등 거짓이나 부정한 방법으로 진료를 받지 아니 한다.
                2. 환자는 자신의 건강 관련 정보를 의료인에게 정확히 알려야 하며, 거짓으로 진료를 볼 경우 발생하는 문제에 대해서는 본인이 모든 책임을 진다.
                3. 비대면 전화 진료 시, 의료진 판단 하에 복양의 안전을 위해 환자의 상태에 따라 처방이 불가 할 수 있다.
                4. 환자는 약 복용 시 발현, 불편증상 등 이상 증세가 있을 경우 대면진료를 받아야 한다.
                5. 의료진 판단 하에 대면 진료를 요구할 수 있으며, 환자는 특별한 사정이 없는 한 이에 응해야 한다.
                6. 비대면 전화 진료의 장점과 주의사항에 대해 확인 및 이해하였다.`
                : ""}
            </div>
            <div
              className={styles.submit}
              onClick={() => setAgreementModalOpen(false)}
            >
              확인
            </div>
          </div>
        </div>
      )}
      {couponModalOpen && (
        <CouponModal
          setOpen={setCouponModalOpen}
          couponList={couponList}
          useCoupon={useCoupon}
          setUseCoupon={setUseCoupon}
        />
      )}
    </div>
  );
}
export default Order;
