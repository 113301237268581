import styles from './OrderComplete.module.scss';
import orderComplete from '../../assets/orderComplete.png';
import imsiProduct from '../../assets/imsi/imsiProduct4.png';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosClient from '../../libs/axiosClient';
import { WEEKDAY } from '../Questionnaire/Questionnaire';
import rightArrow from '../../assets/rightArrow.png';
import { getPayMethodLabel } from '../../common/getPaymethod';
import { loginCheck } from '../../auth/auth';
import { isDesktop, isMobile } from 'react-device-detect';
import AppDownModal from '../../components/AppDownModal/AppDownModal';

export interface IOrderDataType {
  bank: string;
  bank_account: string | null;
  bank_depositor: string;
  bank_holder: string;
  vbank: string;
  vbank_holder: string;
  vbank_date: string;
  card_info: {
    card_name: string;
    card_number: string;
    card_quota: string;
  };
  delivery_price: number;
  order_name: string;
  order_date: string;
  order_id: string;
  pay_date: string;
  pay_method: string;
  order_paymethod: string;
  combined_order_id: string;
  pay_status: string;
  pickup: string;
  price: number;
  products: {
    amount: number;
    company_id: string;
    company_image: null | string;
    company_name: string;
    count: number;
    discount_price: number;
    image: string;
    option: string;
    order_product_id: number;
    product_id: number;
    review_request: string;
    send_company: null | string;
    send_date: null | string;
    send_number: null | string;
    send_status: string;
    status: string;
    title: string;
    is_reservation: string;
    reservation_at: string | null;
  }[];
  receive_address: string;
  receive_name: string;
  receive_phone: string;
  receive_zipcode: string;
  request_memo: string;
  status: string;
  title: string;
  total_price: number;
  use_coupon: number;
  use_point: number;
  use_promotion: number;
}
function OrderComplete() {
  const existPayMethod: string | string[] = [];
  const navigate = useNavigate();
  const params = useParams();
  const [isVirtual, setIsVirtual] = useState(false); // 하나라도 가상계좌 있는지 확인
  const [orderInfoData, setOrderInfoData] = useState<any[]>([]);
  const [orderData, setOrderData] = useState<IOrderDataType[]>();
  const [productIdList, setProductIdList] = useState<number[]>([]);
  const [reservationDate, setReservationDate] = useState('');
  const [isLogin, setIsLogin] = useState(false);
  const [appDownOpen, setAppDownOpen] = useState(false);

  useEffect(() => {
    setIsLogin(loginCheck());
  }, []);

  useEffect(() => {
    // if (isLogin)
    axiosClient
      .get(`/data/order?order_id=${params.orderNumber}`)
      .then((res) => {
        if (!res.data.data.length) {
          alert('주문번호가 올바르지 않거나 권한이 없습니다.');
          navigate('/');
        }
        setOrderData(res.data.data);
        setOrderInfoData(res.data.data[1]);
        const hasVirtual = res.data.data[1]
          .map((item: any) => item.paymethod)
          .includes('virtual');
        if (hasVirtual) setIsVirtual(true);
        sessionStorage.removeItem('quesInfo');
        sessionStorage.removeItem('addrInfo');
        sessionStorage.removeItem('temporaryOrderInfo');
      })
      .catch((err) => {
        axiosClient
          .get(`/data/order?order_id=${params.orderNumber}`)
          .then((res) => {
            if (!res.data.data.length) {
              alert('주문번호가 올바르지 않거나 권한이 없습니다.');
              navigate('/');
            }
            setOrderData(res.data.data);
          });
      });
    // else if (!isLogin)
    //   axiosClient
    //     .get(`/data/order/non-user?order_id=${params.orderNumber}&name=${name}&phone=${tel}`)
    //     .then((res) => {
    //       setOrderData(res.data.data);
    //       setOrderInfoData(res.data.data[1]);
    //     })
    //     .catch((err) => {
    //       navigate('/');
    //     });
  }, []);

  const [paymethodLabel, seyPaymethodLabel] = useState('');

  useEffect(() => {
    if (!orderData) return;

    let isDiet = false;
    if (
      orderData[0].products[0].product_id === 136 &&
      !orderData[0].products[0].option.includes('정기배송')
    )
      isDiet = true;

    let isPay = orderData[0].pay_status === '결제완료' ? true : false;

    // 피씨 또는 모바일 웹 / 구독외 프로그램 상품 구매 경우 / 결제 완료
    if (
      (isDesktop || (isMobile && !localStorage.getItem('mobileApp'))) &&
      isDiet &&
      isPay
    )
      setAppDownOpen(true);

    // 앱 / 구독외 프로그램 상품 구매 경우 / 결제 완료
    if (localStorage.getItem('mobileApp') && isDiet && isPay)
      setTimeout(() => {
        navigate('/dietprogram');
      }, 3000);
    // return navigate('/dietprogram');

    let list: number[] = [];
    orderData[0].products?.map((product) => {
      if (!reservationDate && product.is_reservation == 'Y')
        setReservationDate(product.reservation_at!);
      if (!list.includes(product.product_id)) list.push(product.product_id);
    });
    setProductIdList(list);
    if (orderData[0].pay_method == 'online') return;
    list.map((id) => {
      let transaction_id = '';
      let event_category = '';
      let event_label = '';
      let value = 0;
      orderData[0].products.map((product) => {
        if (id == product.product_id) {
          transaction_id = orderData[0].order_id + `_${product.product_id}`;
          event_category = product.product_id + (product.company_name || '');
          event_label = `상품구매-${product.title}(${product.product_id})${
            product.company_name && `-${product.company_name}`
          }`;
          value += product.amount;
        }
      });
      (window as any).gtag('event', 'purchase', {
        transaction_id,
        event_category,
        event_label,
        value,
      });
    });
  }, [orderData]);
  function copyAddress() {
    navigator.clipboard
      .writeText('서울 강남구 테헤란로64길 8 지하1층')
      .then((res) => alert('주소가 복사되었습니다.'));
  }
  return (
    <div>
      <div className={styles.header}>
        <span onClick={() => navigate('/')}>닫기</span>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.top}>
          <img src={orderComplete} alt="orderComplete" />
          <h3>{reservationDate ? '예약 완료' : '주문 완료'}</h3>
          <div className={styles.btns}>
            <button
              onClick={() => navigate(`/orderDetail/${params.orderNumber}`)}
            >
              {reservationDate ? '예약 ' : '주문 '} 상세보기
            </button>
            <button onClick={() => navigate('/')}>쇼핑 계속하기</button>
          </div>
        </div>
        {orderData && orderData[0] && isVirtual && (
          <div className={styles.withoutBankbookInfo}>
            <div className={styles.bankAlertWrap}>
              <span>아래의 계좌로 </span>
              <span className={styles.hit}>
                입금해 주시면 정상적으로 결제 완료
              </span>
              <span>됩니다.</span>
            </div>
            <div className={styles.bankInfoListWrap}>
              {orderInfoData &&
                orderInfoData.map((info, idx) =>
                  info.paymethod === 'virtual' ? (
                    <div key={idx} className={styles.bankInfoFrame}>
                      <div className={styles.titleWrap}>
                        <span>{idx + 1}</span>
                        <span>계좌 정보</span>
                      </div>
                      <div className={styles.bankNum}>
                        <span>가상계좌</span>
                        <span>{`[${info.vbank}]${info.vbankno}`}</span>
                      </div>
                      <div className={styles.datePriceWrap}>
                        <div className={styles.dateWrap}>
                          <span>입금기한</span>
                          <span>{`${info.vbank_date
                            ?.substring(0, 10)
                            .replace(/-/gi, '.')}`}</span>
                        </div>
                        <div className={styles.priceWrap}>
                          <span>입금금액</span>
                          <span>{`${Number(
                            info.price,
                          ).toLocaleString()}원`}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div key={idx} className={styles.bankInfoFrame}>
                      <div className={styles.titleWrap}>
                        <span>{idx + 1}</span>
                        <span>결제 정보</span>
                      </div>
                      <div className={styles.bankNum}>
                        <span>결제 방법</span>
                        <span>{`${getPayMethodLabel(info.paymethod)}`}</span>
                      </div>
                    </div>
                  ),
                )}
            </div>
          </div>
        )}
        <div className={styles.orderNumberWrap}>
          <span>주문번호 : {orderData && orderData[0]?.order_id} </span>
          <span>
            {orderData &&
              orderData[0]?.order_date?.substring(0, 10).replace(/-/gi, '.')}
          </span>
        </div>
        <ul className={styles.orderItemList}>
          {orderData &&
            productIdList.map((id) => {
              let optionList: string[] = [];
              let count = 0;
              let productImage = '';
              let productName = '';
              orderData[0].products
                .filter((product) => product.product_id == id)
                .map((product) => {
                  optionList.push(product.option);
                  count += product.count;
                  productImage = product.image;
                  productName = product.title;
                });
              return (
                <li key={id}>
                  <div
                    className={styles.productThumbnailWrap}
                    onClick={() => navigate(`/productDetail/${id}`)}
                  >
                    <img src={productImage} alt="thumbnail" />
                  </div>
                  <div className={styles.productInfoWrap}>
                    <span className={styles.productName}>{productName}</span>
                    <div className={styles.optionListWrap}>
                      <span>옵션</span>
                      <ul>
                        {optionList.map((option, idx) => (
                          <li key={idx}>{option}</li>
                        ))}
                      </ul>
                    </div>
                    <div className={styles.countWrap}>
                      <span>수량</span>
                      <span>{count}개</span>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
        {reservationDate && (
          <div className={styles.reservationInfoWrap}>
            <h3 className={styles.sectionTitle}>방문 예약 정보</h3>
            <div className={styles.reservationInfo}>
              <div className={styles.infoWrap}>
                <span>예약병원</span>
                <span>
                  마디로 한의원 선릉점
                  <div
                    className={styles.mapBtn}
                    onClick={() => {
                      window.open(
                        'https://map.naver.com/v5/search/%EB%A7%88%EB%94%94%EB%A1%9C%ED%95%9C%EC%9D%98%EC%9B%90/place/1036931386?c=15,0,0,0,dh&placePath=%3Fentry%253Dbmp',
                        '_blank',
                      );
                    }}
                  >
                    <span>지도보기</span>
                    <img src={rightArrow} alt="화살표" />
                  </div>
                </span>
              </div>
              <div className={styles.infoWrap}>
                <span>병원주소</span>
                <span>
                  서울 강남구 테헤란로64길 8 지하1층{' '}
                  <div className={styles.copyBtn} onClick={copyAddress}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.9583 4.66675V11.0834H3.79163V1.16675H8.74996M11.9583 4.66675H8.74996V1.16675M11.9583 4.66675L8.74996 1.16675"
                        stroke="#FF5656"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.04163 5.8335V12.8335H8.16663"
                        stroke="#FF5656"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.54163 5.8335H6.70829M5.54163 8.16683H9.04163"
                        stroke="#FF5656"
                        strokeLinecap="square"
                      />
                    </svg>
                    <span>복사</span>
                  </div>
                </span>
              </div>
              <div className={styles.infoWrap}>
                <span>예약날짜</span>
                <span>
                  {`${reservationDate.slice(0, 10).replace(/-/gi, '.')}(${
                    WEEKDAY[new Date(reservationDate).getDay()]
                  }) / ${reservationDate.slice(11, 16)}`}
                </span>
              </div>
            </div>
          </div>
        )}
        {orderData &&
          orderData[0] &&
          orderData[0].pickup == 'Y' &&
          !reservationDate && (
            <div className={styles.reservationInfoWrap}>
              <h3 className={styles.sectionTitle}>방문 수령 정보</h3>
              <div className={styles.reservationInfo}>
                <div className={styles.infoWrap}>
                  <span>수령병원</span>
                  <span>
                    마디로 한의원 선릉점{' '}
                    <div
                      className={styles.mapBtn}
                      onClick={() => {
                        window.open(
                          'https://map.naver.com/v5/search/%EB%A7%88%EB%94%94%EB%A1%9C%ED%95%9C%EC%9D%98%EC%9B%90/place/1036931386?c=15,0,0,0,dh&placePath=%3Fentry%253Dbmp',
                          '_blank',
                        );
                      }}
                    >
                      <span>지도보기</span>
                      <img src={rightArrow} alt="화살표" />
                    </div>
                  </span>
                </div>
                <div className={styles.infoWrap}>
                  <span>병원주소</span>
                  <span>
                    서울 강남구 테헤란로64길 8 지하1층{' '}
                    <div className={styles.copyBtn} onClick={copyAddress}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M11.9583 4.66675V11.0834H3.79163V1.16675H8.74996M11.9583 4.66675H8.74996V1.16675M11.9583 4.66675L8.74996 1.16675"
                          stroke="#FF5656"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.04163 5.8335V12.8335H8.16663"
                          stroke="#FF5656"
                          strokeLinecap="square"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.54163 5.8335H6.70829M5.54163 8.16683H9.04163"
                          stroke="#FF5656"
                          strokeLinecap="square"
                        />
                      </svg>
                      <span>복사</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          )}
        <div className={styles.deliveryInfoWrap}>
          <h3 className={styles.sectionTitle}>배송지 정보</h3>
          <div className={styles.deliveryInfo}>
            <div className={styles.line}>
              <div className={styles.infoWrap}>
                <span>주문자명</span>
                <span>{orderData && orderData[0].order_name}</span>
              </div>
              <div className={styles.infoWrap}>
                <span>수령인</span>
                <span>{orderData && orderData[0].receive_name}</span>
              </div>
            </div>
            <div className={styles.infoWrap}>
              <span>연락처</span>
              <span>{orderData && orderData[0].receive_phone}</span>
            </div>
            <div className={styles.infoWrap}>
              <span>주소지</span>
              <span>{orderData && orderData[0].receive_address}</span>
            </div>
            <div className={styles.infoWrap}>
              <span>배송메세지</span>
              <span>{orderData && orderData[0].request_memo}</span>
            </div>
          </div>
        </div>
        <div className={styles.priceInfoWrap}>
          <h3 className={styles.sectionTitle}>총 결제 금액</h3>
          <div className={styles.priceInfo}>
            <div>
              <span>상품가격</span>
              <span>₩ {orderData && orderData[0].price?.toLocaleString()}</span>
            </div>
            <div>
              <span>할인금액</span>
              <span>
                ₩{' '}
                {orderData &&
                  (
                    orderData[0].use_coupon +
                    orderData[0].use_point +
                    orderData[0].use_promotion
                  )?.toLocaleString()}
              </span>
            </div>
            <div>
              <span>배송비</span>
              <span>
                {orderData &&
                  (orderData[0].delivery_price == 0
                    ? '무료'
                    : orderData[0].delivery_price?.toLocaleString())}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.totalPrice}>
          <span>총 결제 금액</span>
          <span>
            {orderData && orderData[0].total_price?.toLocaleString()}원
          </span>
        </div>
        <div className={styles.payMethodInfoWrap}>
          <h3>결제 수단</h3>
          {/* {orderData && orderData[0].card_info?.card_name && (
            <span>
              신용카드 ({orderData[0].card_info?.card_name}/
              {orderData[0].card_info?.card_quota == '00'
                ? '일시불'
                : orderData[0].card_info?.card_quota + '개월'}
              )
            </span>
          )}
          {orderData &&
            orderData[0].order_paymethod !==
              ('virtual' || 'online' || 'card') && (
              <span>{paymethodLabel}</span>
            )} */}
          {orderData && orderData[0].bank_account ? (
            <div className={styles.paymethodWrap}>
              {orderInfoData &&
                orderInfoData.map((info, idx) =>
                  info.paymethod === 'virtual' ? (
                    <div key={idx} className={styles.paymethodFrame}>
                      <div className={styles.labelWrap}>가상계좌</div>
                      <div className={styles.contentWrap}>
                        <div className={styles.leftWrap}>
                          <span>{`[${info.vbank}]${info.vbankno}`}</span>
                          <span>
                            {`${info.vbank_date
                              ?.substring(0, 10)
                              .replace(/-/gi, '.')}`}
                            까지
                          </span>
                        </div>
                        <div className={styles.rightWrap}>
                          <span>
                            {`${Number(info.price).toLocaleString()}원`}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.paymethodFrame} key={idx}>
                      <div className={styles.labelWrap}>
                        {' '}
                        {getPayMethodLabel(info.paymethod)}
                      </div>
                    </div>
                  ),
                )}
            </div>
          ) : (
            orderData &&
            !orderData[0].bank_account && (
              <div className={styles.paymethodWrap}>
                {orderInfoData &&
                  orderInfoData.map((info, idx) => {
                    if (info.paymethod === 'virtual') {
                      return (
                        <div key={idx} className={styles.paymethodFrame}>
                          <div className={styles.labelWrap}>가상계좌</div>
                          <div className={styles.contentWrap}>
                            <div className={styles.leftWrap}>
                              <span>{`[${info.vbank}]${info.vbankno}`}</span>
                              <span>
                                {`${info.vbank_date
                                  ?.substring(0, 10)
                                  .replace(/-/gi, '.')}`}
                                까지
                              </span>
                            </div>
                            <div className={styles.rightWrap}>
                              <span>
                                {`${Number(info.price).toLocaleString()}원`}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      const payMethodLabel = getPayMethodLabel(info.paymethod);
                      const isExist = existPayMethod.includes(payMethodLabel);
                      if (!isExist) {
                        existPayMethod.push(payMethodLabel);
                        return (
                          <div key={idx} className={styles.paymethodFrame}>
                            <div className={styles.labelWrap}>
                              {payMethodLabel}
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }
                  })}
              </div>
            )
          )}
        </div>
      </div>
      {appDownOpen && <AppDownModal closer={setAppDownOpen} />}
    </div>
  );
}
export default OrderComplete;
