/** @format */

import styles from './ProductDetail.module.scss';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import bottomArrow from '../../assets/bottomArrow02.png';
import bottomArrow2 from '../../assets/bottomArrow03.png';
import productdetailevent from '../../assets/productdetailevent.png';
import productdetaileventBtn from '../../assets/productdetaileventBtn.png';
import globalPayEvent from '../../assets/globalPayEvent.png';
import globalpay2 from '../../assets/globalpay2.png';
import globalPayBtn2 from '../../assets/globalPayBtn2.png';
import globalPayEventBtn1 from '../../assets/globalPayEventBtn1.png';
import globalPayEventBtn2 from '../../assets/globalPayEventBtn2.png';
import star from '../../assets/star.png';
import review from '../../assets/review.png';
import emptyStar from '../../assets/emptyStar.png';
import minus from '../../assets/minus.png';
import plus from '../../assets/plus.png';
import heart from '../../assets/heart.png';
import redHeart from '../../assets/redHeart.png';
import basket from '../../assets/basket.png';
import detailFixFormClose from '../../assets/detailFixFormClose.png';
import closeBtn from '../../assets/closeBtn02.png';
import review01 from '../../assets/review/reviewImage01.jpeg';
import review02 from '../../assets/review/reviewImage02.jpeg';
import review03 from '../../assets/review/reviewImage03.jpeg';
import review04 from '../../assets/review/reviewImage04.jpeg';
import review05 from '../../assets/review/reviewImage05.jpeg';
import brand from '../../assets/brand.jpeg';
import Review from '../../components/Review/Review';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
import 'swiper/css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import axiosClient from '../../libs/axiosClient';
import { loginCheck } from '../../auth/auth';
import { loginConfirm, loginConfirmRurl } from '../../common/loginConfirm';
import ReviewFilter from '../../components/ReviewFilter/ReviewFilter';
import { isDesktop, isMobile } from 'react-device-detect';
import { IReviewDataType } from '../MyReview/MyReview';
import LazyLoad from 'react-lazy-load';
import BiumhwanModal from '../../components/BiumhwanModal/BiumhwanModal';
import GambiteaModal from '../../components/GambiteaModal/GambiteaModal';
import ADLink from '../../components/ADLink/ADLink';
import ADBanner from '../../components/ADBanner/ADBanner';
import Header2 from '../../components/Header2/Header2';
import getChallengeData from '../../common/getChallengeData';
import ReactPixel from 'react-facebook-pixel';
import kakaoPlusPc from '../../assets/kakaoPlus/kakaoPlusPc.png';
import kakaoPlusMobile from '../../assets/kakaoPlus/kakaoPlusMobile.png';
import kakaoDirect from '../../assets/kakaoPlus/kakaoDirect.png';
import ccaBack1 from '../../assets/cca/ccaBack1.jpg';
import ccaBack2 from '../../assets/cca/ccaBack2.jpg';
import ccaBack3 from '../../assets/cca/ccaBack3.jpg';
import zeroBack1 from '../../assets/zero/zeroBack1.jpg';
import zeroBack2 from '../../assets/zero/zeroBack2.jpg';
import zeroBack3 from '../../assets/zero/zeroBack3.jpg';
import adProduct from '../../assets/adBanner/adProduct.png';
import adDirect from '../../assets/adBanner/adDirect.png';
import PrescriptionLoginModal from '../../components/PrescriptionLoginModal/PrescriptionLoginModal';
import NonMemberModal from '../../components/NonMemberModal/NonMemberModal';
import biumBack1 from '../../assets/productDetail/background/biumBack1.jpg';
import biumBack2 from '../../assets/productDetail/background/biumBack2.jpg';
import saladBack1 from '../../assets/productDetail/background/saladBack1.jpg';
import saladBack2 from '../../assets/productDetail/background/saladBack2.jpg';
import saladBack3 from '../../assets/productDetail/background/saladBack3.jpg';
import dietBack1 from '../../assets/productDetail/diet/dietBack1.jpg';
import dietBack2 from '../../assets/productDetail/diet/dietBack2.jpg';
import dietBack3 from '../../assets/productDetail/diet/dietBack3.jpg';
import appleBtn from '../../assets/dietProgram/appleBtn.png';
import googleBtn from '../../assets/dietProgram/googleBtn.png';
import ProgramNonMemberModal from '../../components/ProgramNonMemberModal/ProgramNonMemberModal';

SwiperCore.use([Scrollbar]);
export interface IAllItemsType {
  first: IOptionType;
  second?: IOptionType;
  third?: IOptionType;
  count: number;
  key?: number;
}
export interface IProductDetailType {
  company_name: string;
  content: string;
  cp_delivery_freeprice: number;
  cp_delivery_price: number;
  cp_delivery_use: string;
  delivery_case_price: number;
  delivery_cost: number;
  delivery_description: string;
  delivery_price: number;
  delivery_type: string;
  is_prescription: number;
  delivery_price_info: {
    delivery_company: string;
    delivery_complain_fail: string;
    delivery_complain_ok: string;
    delivery_complain_price: string;
    delivery_date: string;
    delivery_return_addr: string;
  };
  detail_url: string;
  discount_price: number;
  discount_rate: number;
  hashtag: string;
  id: string;
  image: string;
  is_like: number;
  list_image: string;
  option_depth: number;
  p_shoppingPayFree: number;
  p_shoppingPayPdPrice: number;
  p_shoppingPayPfPrice: number;
  price: number;
  product_id: number;
  provision_notice: null;
  review_score_avg: {
    product_id: number;
    review_point_0_avg: number;
    review_point_1_avg: number;
    review_point_2_avg: number;
    review_point_3_avg: number;
    review_point_total_avg: number;
    review_total_count: number;
  };
  relation_product?: {
    id: number;
    price: number;
    sale_price: number;
  };
  soldout: string;
  stock_count: number;
  title: string;
  is_sales_started: string;
  selling_end_at: string;
  selling_started_at: string;
}
export interface IOptionType {
  cycle_limit?: number;
  cycle_type?: string;
  po_cnt?: number;
  po_color_name?: string;
  po_color_type?: string;
  po_depth?: number;
  po_parent?: string | null;
  po_pcode?: string;
  po_poption_supplyprice?: number;
  po_poptionname?: string;
  po_poptionprice?: number;
  po_salecnt?: number;
  po_sort?: number;
  po_temp_cosuid?: string | null;
  po_uid: number;
  po_view?: string;
  alert_synergy?: string;
}
function ProductDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const [prescriptionProgramList, setPrescriptionProgramList] = useState<any>([
    21, 108, 107,
  ]);
  const { isDirect } = useParams();
  const { cartId } = useParams();
  const location = useLocation();
  const [plusModalOpen, setPlusModalOpen] = useState(false);
  const [detail, setDetail] = useState<IProductDetailType>();
  const { data: detailCacheData } = useQuery<IProductDetailType>(
    ['productDetail', params.productId],
    () =>
      axiosClient
        .get(`/data/product/detail/${params.productId}`)
        .then((res) => {
          let list = JSON.parse(
            sessionStorage.getItem('product_ids') || JSON.stringify([]),
          );
          if (!list.includes(res.data.data.product_id))
            list.push(res.data.data.product_id);
          sessionStorage.setItem('product_ids', JSON.stringify(list));
          return res.data.data;
        }),
    {
      retry: 2,
      refetchOnWindowFocus: false,
      onError: () => {
        alert('오류가 발생했습니다.');
        navigate('/');
      },
    },
  );
  useEffect(() => {
    if (detailCacheData) setDetail(detailCacheData);
  }, [detailCacheData]);

  const [isBuyDiet, setIsBuyDiet] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (loginCheck()) setIsLogin(true);
    else setIsLogin(false);
  }, []);

  useEffect(() => {
    if (isLogin)
      axiosClient
        .get('/data/subscription/check', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          const result = res.data.data;
          if (result.is_program === 'Y') setIsBuyDiet(true);
          else setIsBuyDiet(false);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [isLogin]);

  useEffect(() => {
    if (location.pathname === '/productDetail/111') ReactPixel.pageView();
  }, [location]);

  // 옵션 관련 상태
  const [firstOptionOpen, setFirstOptionOpen] = useState(false);
  const [selectedFirstOption, setSelectedFirstOption] = useState<IOptionType>();
  const [secondOptionOpen, setSecondOptionOpen] = useState(false);
  const [selectedSecondOption, setSelectedSecondOption] =
    useState<IOptionType>();
  const [thirdOptionOpen, setThirdOptionOpen] = useState(false);
  const [selectedThirdOption, setSelectedThirdOption] = useState<IOptionType>();
  const [firstOptionList, setFirstOptionList] = useState<IOptionType[]>();
  const [secondOptionList, setSecondOptionList] = useState<IOptionType[]>();
  const [thirdOptionList, setThirdOptionList] = useState<IOptionType[]>();
  const [selectedAllItems, setSelectedAllItems] = useState<IAllItemsType[]>([]);
  const [allItemsPrice, setAllItemsPrice] = useState(0);
  const [allItemsCount, setAllItemsCount] = useState(0);
  //다이어트프로그램만 옵션 아이디
  const [programOption, setProgramOption] = useState<any[]>([]);
  //다이어트프로그램+구독 옵션 아이디
  const [allProgramOption, setAllProgramOption] = useState<any[]>([]);
  //구독 1단계 옵션 아이디
  const [subscribeOption, setSubscribeOption] = useState();
  //구독 2단계 옵션 아이디
  const [subscribeOption2, setSubscribeOption2] = useState<any[]>([]);

  useEffect(() => {
    axiosClient
      .get(`/data/product/detail/${params.productId}/options`)
      .then((res) => {
        setFirstOptionList(res.data.data);
        if (params.productId === '136') {
          const options = res.data.data.map((item: any) => item.po_uid);
          const programOptions = options.slice(0, -1);
          setProgramOption(programOptions);
          setAllProgramOption(options);
          if (programOptions.length > 0)
            setSubscribeOption(options[options.length - 1]);

          if (location.state?.programId)
            setSelectedFirstOption(res.data.data[location.state.programId]);
        }
      });
  }, []);
  // 첫 번째 옵션 선택시
  useEffect(() => {
    if (!selectedFirstOption || !detail) return;
    // option_depth가 1보다 크면 2depth의 option 정보 받아옴
    if (detail.option_depth > 1) {
      axiosClient
        .get(
          `/data/product/detail/${detail.product_id}/options?option_id=${selectedFirstOption.po_uid}&depth=2`,
        )
        .then((res) => setSecondOptionList(res.data));
      setSecondOptionOpen(true);
    } else {
      //이미 선택된 옵션 중에 같은 옵션이 포함되어있는지 있으면 해당 idx추출
      let includeIdx = -1;
      selectedAllItems.map((item, idx) => {
        if (item.first?.po_uid == selectedFirstOption.po_uid) includeIdx = idx;
      });
      //-1에서 변경이 없다면 기존 선택된 옵션에 포함되어 있지 않은 옵션이므로 새로 추가
      if (includeIdx == -1)
        setSelectedAllItems([
          ...selectedAllItems,
          {
            first: selectedFirstOption,
            count: 1,
          },
        ]);
      //-1이 아닌 변경된 값이면 이미 해당 옵션이 선택되어 있으므로 해당 옵션 수량 증가
      else
        setSelectedAllItems((prev) => {
          let newList = prev;
          newList[includeIdx].count += 1;
          return [...newList];
        });
      setSelectedFirstOption(undefined);
      setSelectedSecondOption(undefined);
    }
    setFirstOptionOpen(false);
  }, [selectedFirstOption]);
  // 첫 번째 옵션 선택시
  // 두 번째 옵션 선택시
  useEffect(() => {
    if (!selectedSecondOption || !selectedFirstOption || !detail) return;
    if (detail.option_depth > 2) {
      axiosClient
        .get(
          `/data/product/detail/${detail.product_id}/options?option_id=${selectedSecondOption.po_uid}&depth=3`,
        )
        .then((res) => setThirdOptionList(res.data));
      setThirdOptionOpen(true);
    } else {
      let includeIdx = -1;
      selectedAllItems.map((item, idx) => {
        if (
          item.first?.po_uid == selectedFirstOption.po_uid &&
          item.second?.po_uid == selectedSecondOption.po_uid
        )
          includeIdx = idx;
      });

      if (isBuyDiet && selectedSecondOption.cycle_type === 'Once') {
        setSelectedFirstOption(undefined);
        setSelectedSecondOption(undefined);
        setSecondOptionOpen(false);
        return alert(
          '다이어트 프로그램은 진행 기간 동안 중복 구매가 불가합니다.',
        );
      }

      if (includeIdx == -1) {
        // 새로추가
        if (
          allProgramOption.includes(selectedFirstOption.po_uid) &&
          selectedAllItems.length > 0
        ) {
          if (
            subscribeOption === selectedAllItems[0].first.po_uid &&
            subscribeOption === selectedFirstOption.po_uid
          ) {
          } else {
            setSelectedFirstOption(undefined);
            setSelectedSecondOption(undefined);
            setSecondOptionOpen(false);
            return alert(
              '다이어트 프로그램과 구독 상품은 동시에 구매 불가하며 다이어트 프로그램은 1세트만 구매 가능합니다.',
            );
          }
        }
        let isBuy = true;
        if (selectedSecondOption.alert_synergy)
          isBuy = window.confirm(
            '첫 복용은 시너지플러스 없이도 효과가 충분합니다. 복용해보시고 시너지플러스는 나중에 처방받으셔도 됩니다. 그래도 구매하시겠습니까?',
          );
        if (!isBuy) {
          setSelectedFirstOption(undefined);
          return;
        }
        setSelectedAllItems([
          ...selectedAllItems,
          {
            first: selectedFirstOption,
            second: selectedSecondOption,
            count: 1,
          },
        ]);
      } else {
        // 기존추가
        if (programOption.includes(selectedFirstOption.po_uid)) {
          setSelectedFirstOption(undefined);
          setSelectedSecondOption(undefined);
          setSecondOptionOpen(false);
          return alert(
            '다이어트 프로그램과 구독 상품은 동시에 구매 불가하며 다이어트 프로그램은 1세트만 구매 가능합니다.',
          );
        }
        setSelectedAllItems((prev) => {
          let newList = prev;
          newList[includeIdx].count += 1;
          return [...newList];
        });
      }
      setSelectedFirstOption(undefined);
      setSelectedSecondOption(undefined);
    }
    setSecondOptionOpen(false);
  }, [selectedSecondOption]);
  // 두 번째 옵션 선택시
  function handleCountChange(index: number, isUp: boolean) {
    setSelectedAllItems((prev) =>
      prev.map((item, idx) => {
        if (idx == index) {
          if (isUp) item.count = item.count + 1;
          else if (!isUp && item.count > 1) item.count = item.count - 1;
        }
        return item;
      }),
    );
  }
  function handleSelectedItemDelete(index: number) {
    setSelectedAllItems((prev) => prev.filter((item, idx) => idx !== index));
  }
  // 선택한 옵션 총 가격
  useEffect(() => {
    let newPrice = 0;
    let newCount = 0;
    for (let i = 0; i < selectedAllItems.length; i++) {
      let price = 0;
      if ('third' in selectedAllItems[i])
        price += selectedAllItems[i].third?.po_poptionprice || 0;
      else if ('second' in selectedAllItems[i])
        price += selectedAllItems[i].second?.po_poptionprice || 0;
      else if ('first' in selectedAllItems[i])
        price += selectedAllItems[i].first?.po_poptionprice || 0;
      newPrice = newPrice + price * selectedAllItems[i].count;
      newCount = newCount + selectedAllItems[i].count;
    }
    setAllItemsPrice(newPrice);
    setAllItemsCount(newCount);
  }, [selectedAllItems]);
  // 선택한 옵션 총 가격
  // 옵션 관련 상태

  function needLogin() {
    return loginConfirm();
  }
  function getBasketFormData() {
    if (!detail) return;
    let formData = new FormData();
    formData.append('product_id', String(detail.product_id));
    if (detail && !prescriptionProgramList.includes(detail.product_id)) {
      for (let i = 0; i < selectedAllItems.length; i++) {
        if (detail.option_depth <= 1) {
          if (!selectedAllItems[i].first?.po_uid)
            formData.append('option_ids', '0');
          else
            formData.append(
              'option_ids',
              String(selectedAllItems[i].first?.po_uid),
            );
          formData.append('counts', String(selectedAllItems[i].count));
        } else if (detail.option_depth == 2) {
          formData.append(
            'option_ids',
            String(selectedAllItems[i].second?.po_uid),
          );
          formData.append('counts', String(selectedAllItems[i].count));
        } else if (detail.option_depth == 3) {
          formData.append(
            'option_ids',
            String(selectedAllItems[i].third?.po_uid),
          );
          formData.append('counts', String(selectedAllItems[i].count));
        }
      }
    } else {
      formData.append('option_ids', '0');
      formData.append('counts', '1');
    }
    return formData;
  }

  useEffect(() => {
    if (firstOptionOpen && secondOptionOpen) setSecondOptionOpen(false);
  }, [firstOptionOpen, secondOptionOpen]);

  function optionSelectCheck() {
    if (detail && prescriptionProgramList.includes(detail.product_id))
      return true;
    if (selectedAllItems.length == 0) {
      if (!selectedFirstOption) {
        alert('첫 번째 옵션을 선택해주세요.');
        setFirstOptionOpen(true);
      } else if (!selectedSecondOption) {
        alert('두 번째 옵션을 선택해주세요.');
        setSecondOptionOpen(true);
      } else if (!selectedThirdOption) {
        alert('세 번째 옵션을 선택해주세요.');
        setThirdOptionOpen(true);
      }
      return false;
    } else {
      return true;
    }
  }
  function saleDateCheck() {
    if (!detail) return false;
    if (new Date(detail?.selling_end_at).getTime() < new Date().getTime()) {
      alert('판매기간이 종료된 상품입니다.');
      return false;
    }
    if (detail?.is_sales_started == 'N') {
      alert(
        `${detail.selling_started_at.substring(
          0,
          4,
        )}년 ${detail.selling_started_at.substring(
          5,
          7,
        )}월 ${detail.selling_started_at.substring(
          8,
          10,
        )} 일부터 주문이 가능한 상품입니다.`,
      );
      return false;
    } else return true;
  }
  function handleBasket() {
    // 비로그인용 장바구니 코드 삽입 필요
    if (!saleDateCheck()) return;
    let check = optionSelectCheck();
    if (!check) return;
    let formData = getBasketFormData() as FormData;
    axiosClient
      .post('/func/cart', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': 'https://api.ondoc.co.kr',
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.data.cart_ids[0].message) {
          alert(res.data.data.cart_ids[0].message);
        } else {
          alert('상품을 장바구니에 담았습니다.');
          (window as any).gtag('event', 'add_to_cart', {
            items: [
              {
                item_id: detail?.product_id, // 상품 고유 ID
                item_name: detail?.title, // 상품 이름
                quantity: allItemsCount, // 수량
                price: allItemsPrice,
              },
            ],
            event_label: `장바구니-${detail?.title}(${detail?.product_id})${
              detail?.company_name && `-${detail?.company_name}`
            }`,
            event_category: detail?.product_id,
            value: allItemsPrice,
          });
        }
      })
      .catch((err) => {
        alert('잠시 후 다시 시도해주세요.');
      });
  }
  function handlePick() {
    if (!detail) return;
    if (!needLogin()) return;
    if (detail.is_like == 0) {
      setDetail((prev) => ({ ...prev!, is_like: 1 }));
      axiosClient.post('/func/like', {
        target_id: detail.product_id,
        target_type: 'Product',
        like_type: '1',
      });
    } else {
      setDetail((prev) => ({ ...prev!, is_like: 0 }));
      axiosClient.delete(
        `/func/like?target_type=Product&target_id=${detail.product_id}`,
      );
    }
  }
  // 네비게이션 바 관련
  const [hitNavigation, setHitNavigation] = useState(0);
  const productDetailRef = useRef<HTMLDivElement>(null);
  const reviewRef = useRef<HTMLDivElement>(null);
  const productInfoRef = useRef<HTMLDivElement>(null);
  function handleScroll(num: number) {
    if (
      !productDetailRef.current ||
      !reviewRef.current ||
      !productInfoRef.current
    )
      return;
    let height = isDesktop ? 207 : 102;
    if (num == 0) {
      window.scrollTo({
        top: productDetailRef.current.offsetTop - height,
      });
    } else if (num == 1) {
      window.scrollTo({
        top: reviewRef.current.offsetTop - height,
      });
    } else if (num == 2) {
      window.scrollTo({
        top: productInfoRef.current.offsetTop - height,
      });
    }
  }
  // 네비게이션 바 관련

  // 스크롤 위치 네비게이션 바 보다 하단일시 상단 고정 구매 탭 관련
  const [isFixed, setIsFixed] = useState(false);
  const [fixedFormOpen, setFixedFormOpen] = useState(false);
  useEffect(() => {
    if (isMobile && fixedFormOpen) document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [fixedFormOpen]);
  const navigationBarLocationRef = useRef<HTMLDivElement>(null);
  const throttledScroll = useMemo(
    () =>
      throttle(() => {
        if (
          !navigationBarLocationRef.current ||
          !reviewRef.current ||
          !productInfoRef.current
        )
          return;
        if (window.scrollY < navigationBarLocationRef.current.offsetTop)
          setAdBanner(true);
        if (window.scrollY > navigationBarLocationRef.current.offsetTop) {
          setIsFixed(true);
          setAdBanner(false);
        } else setIsFixed(false);
        if (window.scrollY < reviewRef.current.offsetTop - 207)
          setHitNavigation(0);
        else if (window.scrollY + 207 < productInfoRef.current.offsetTop - 207)
          setHitNavigation(1);
        else setHitNavigation(2);
      }, 500),
    [],
  );
  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);
  // 스크롤 위치 네비게이션 바 보다 하단일시 상단 고정 구매 탭 관련
  const [nonfaceProcessOpen, setNonfaceProcessOpen] = useState(false);

  const [prescriptionLoginOpen, setPrescriptionLoginOpen] = useState(false);
  const [nonMemberOpen, setNonMemberOpen] = useState(false);
  const [programNonMemberOpen, setProgramNonMemberOpen] = useState(false);

  function handleBuy() {
    if (!detail) return;
    if (!saleDateCheck()) return;
    if (detail.is_prescription === 1) {
      // if (!needLogin()) return;
      if (!loginCheck() && detail?.product_id === 136)
        return setProgramNonMemberOpen(true);
      if (!loginCheck()) return setPrescriptionLoginOpen(true);
    } else {
      if (!loginCheck()) {
        // let nonMember = window.confirm('비회원으로 구매하시겠습니까?');
        // if (!nonMember) return;
        return setNonMemberOpen(true);
      }
    }
    handleBuyContinue();
  }

  const handleBuyContinue = () => {
    if (!detail) return;
    let check = optionSelectCheck();
    if (!check) return;
    let formData = getBasketFormData() as FormData;
    formData.append('is_direct', 'Y');
    axiosClient
      .post('/func/cart', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        if (
          res.data.data.cart_ids[0].message &&
          !prescriptionProgramList.includes(detail.product_id)
        ) {
          alert(res.data.data.cart_ids[0].message);
          return;
        }
        if (
          res.data.data.cart_ids[0].message &&
          prescriptionProgramList.includes(detail.product_id)
        ) {
          if (
            res.data.data.cart_ids[0].message === '옵션이 선택되지 않았습니다.'
          ) {
            if (params.productId === '108' || params.productId === '107')
              alert('품절입니다.');
            else navigate(`/questionnaire/Y/${params.productId}`);
            return;
          }
          alert(res.data.data.cart_ids[0].message);
          return;
        }
        (window as any).gtag('event', 'purchase_attempt', {
          event_category: detail?.product_id,
          event_label: `구매시도-${detail?.title}(${detail?.product_id})${
            detail?.company_name && `-${detail?.company_name}`
          }`,
          value: allItemsPrice,
        });
        let idList: number[] = [];
        res.data.data.cart_ids.map(
          (ids: { status: number; cart_id: number }) => {
            idList.push(ids.cart_id);
          },
        );
        let queryString = JSON.stringify(idList);
        if (
          detail?.is_prescription === 1 &&
          !prescriptionProgramList.includes(detail.product_id)
        ) {
          if (
            selectedAllItems[0].second?.cycle_limit &&
            selectedAllItems[0].second?.cycle_limit > 1
          )
            navigate(`/order-addr/Y/${queryString}`, {
              state: {
                onlyHealth: true,
                isProgram: detail?.product_id === 136 ? true : false,
              },
            });
          // 문진표로 가는 경우 isProgram 확인은 문진표에서 136번으로 확인함
          else navigate(`/questionnaire/Y/${queryString}`);
          // if (detail?.is_prescription === 0) navigate(`/order/Y/${queryString}`);
        }
        if (detail?.is_prescription === 0)
          navigate(`/order-addr/Y/${queryString}`, {
            state: {
              onlyHealth: true,
              isProgram: detail?.product_id === 136 ? true : false,
            },
          });
      });
  };

  //모바일로 접속시 제풍상세 하단탭 높이만큼 body padding-bottom증가
  useEffect(() => {
    if (!isDesktop)
      document.body.style.paddingBottom =
        'calc(env(safe-area-inset-bottom) + 128px)';
    return () => {
      document.body.style.paddingBottom =
        'calc(env(safe-area-inset-bottom) + 64px)';
    };
  }, []);
  //모바일로 접속시 제풍상세 하단탭 높이만큼 body padding-bottom증가

  //첫 렌더링시 리뷰데이터 생성(리뷰 컴포넌트에서 작성 시 리뷰 컴포넌트 두 개라 동일한 요청 두 개씩 발생해서 상위 컴포넌트에서 첫 리뷰 데이터 호출)
  const [reviewData, setReviewData] = useState<IReviewDataType[]>([]);
  const [reviewCount, setReviewCount] = useState(0);
  useEffect(() => {
    //첫 렌더링시 일반 리뷰 데이터 조회
    if (!detail) return;
    axiosClient
      .get(
        `/data/product_talk/list?&talk_type=상품평가&offset=0&limit=3&product_id=${params.productId}&order_by=star`,
      )
      .then((res) => {
        setReviewData(res.data.data);
        setReviewCount(res.data.total_count);
      });
  }, [detail]);
  const [photoReviewData, setPhotoReviewData] = useState<IReviewDataType[]>([]);
  const [photoReviewCount, setPhotoReviewCount] = useState(0);
  useEffect(() => {
    //첫 렌더링시 포토 리뷰 데이터 조회
    if (!detail || !reviewData.length) return;
    axiosClient
      .get(
        `/data/product_talk/photo-review-list?&talk_type=상품평가&offset=0&limit=15&product_id=${params.productId}&order_by=order`,
      )
      .then((res) => {
        setPhotoReviewData(res.data.data);
        setPhotoReviewCount(res.data.total_count);
      });
  }, [detail, reviewData]);
  //첫 렌더링시 리뷰데이터 생성(리뷰 컴포넌트에서 작성 시 리뷰 컴포넌트 두 개라 동일한 요청 두 개씩 발생해서 상위 컴포넌트에서 첫 리뷰 데이터 호출)

  // const [biumhwanOpen, setBiumhwanOpen] = useState(false);
  const [gambiteaOpen, setGambiteaOpen] = useState(false);
  function handleAddEvent() {
    // let element = document.querySelector('.biumhwan');
    // if (!element) return;
    // element.addEventListener('click', () => {
    //   setBiumhwanOpen(true);
    // });
    let element = document.querySelector('.gambitea');
    if (!element) return;
    element.addEventListener('click', () => {
      setGambiteaOpen(true);
    });
  }

  const connectToChannelIO = () => {
    if ((window as any).ChannelIO) (window as any).ChannelIO('openChat');
  };

  const [adModalOpen, setAdModalOpen] = useState(true);
  const [adBanner, setAdBanner] = useState(true);

  return (
    <>
      <div style={{ marginBottom: '-64px' }}>
        {isDesktop && detail?.relation_product && (
          <ADBanner
            endDate={detail.selling_end_at}
            productId={detail.relation_product.id}
            price={detail.relation_product.price}
            discountedPrice={detail.relation_product.sale_price}
          />
        )}
        {isMobile && detail?.relation_product && adBanner && (
          <ADBanner
            endDate={detail.selling_end_at}
            productId={detail.relation_product.id}
            price={detail.relation_product.price}
            discountedPrice={detail.relation_product.sale_price}
          />
        )}
        {/* {isMobile && detail?.relation_product && adBanner ? (
          <Header2 adBanner={adBanner} />
        ) : (
          isMobile && detail?.relation_product && !adBanner && ''
        )}
        {isDesktop && detail?.relation_product && (
          <Header2 adBanner={adBanner} />
        )}
        {!detail?.relation_product && <Header />} */}
        {!detail?.relation_product ? (
          <Header />
        ) : (
          <Header2 adBanner={adBanner} />
        )}
        <div
          style={{
            width: isMobile ? '100%' : '',
          }}
          className={styles.thumbnailWrap}
        >
          {detail && <img src={detail ? detail.image : ''} alt="thumbnail" />}
        </div>
        <div
          className={`${styles.productInfoWrap} ${
            isMobile ? styles.isMobile : ''
          }`}
        >
          <span className={styles.productName}>{detail && detail.title}</span>
          {detail && detail.review_score_avg && (
            <div className={styles.scoreWrap}>
              {/* <ul>
                {(() => {
                  let list: boolean[] = [];
                  for (let i = 1; i <= 5; i++) {
                    if (
                      i <=
                      Math.round(
                        detail.review_score_avg.review_point_total_avg / 2,
                      )
                    )
                      list.push(true);
                    else list.push(false);
                  }
                  return list.map((isTrue, idx) => {
                    if (isTrue)
                      return (
                        <li key={idx}>
                          <img src={star} alt="star" />
                        </li>
                      );
                    else
                      return (
                        <li key={idx}>
                          <img src={emptyStar} alt="star" />
                        </li>
                      );
                  });
                })()}
              </ul> */}
              <ul>
                <li>
                  <img src={review} alt="review" />
                </li>
              </ul>
              <span className={styles.reviewCount}>
                {/* (
                {Math.floor(
                  (detail.review_score_avg.review_point_total_avg / 2) * 10,
                ) / 10}
                ) */}
                {detail.review_score_avg?.review_total_count.toLocaleString()}
              </span>
            </div>
          )}
          <div className={styles.priceInfoWrap}>
            <div
              className={`${styles.priceWrap} ${
                !(
                  detail?.discount_price !== detail?.price &&
                  detail?.price !== 0
                )
                  ? styles.nosale
                  : ''
              }`}
            >
              <span>판매가</span>
              <span>
                {detail &&
                  (detail.price !== 0
                    ? detail.price.toLocaleString()
                    : detail.discount_price.toLocaleString())}
                원
              </span>
            </div>
            {detail?.discount_price !== detail?.price &&
              detail?.price !== 0 && (
                <div className={styles.discountPriceWrap}>
                  <span>할인가</span>
                  <span className={styles.price}>
                    <span>
                      {detail && detail.discount_price.toLocaleString()}원
                    </span>
                    {detail?.is_prescription == 1 && (
                      <span className={styles.fromHospital}>
                        * 해당 할인은 한의원에서 진행하는 할인입니다.
                      </span>
                    )}
                  </span>
                </div>
              )}
          </div>
        </div>
        <div
          className={`${styles.optionWrap} ${isMobile ? styles.isMobile : ''}`}
        >
          {/* 썸네일 밑에 옵션 */}
          {detail && !prescriptionProgramList.includes(detail.product_id) && (
            <div className={styles.option}>
              <span>필수 옵션</span>
              <div
                className={styles.optionBox}
                onClick={() => setFirstOptionOpen((prev) => !prev)}
              >
                <span
                  style={{
                    color: selectedFirstOption ? '#131C31' : '#abb1bb',
                  }}
                >
                  {selectedFirstOption
                    ? selectedFirstOption.po_poptionname
                    : '옵션을 선택해주세요.'}
                </span>
                <img
                  src={bottomArrow}
                  alt="bottomArrow"
                  style={{
                    transform: firstOptionOpen ? 'rotate(180deg)' : 'none',
                  }}
                />
                {firstOptionOpen && (
                  <ul className={styles.optionList}>
                    {detail.option_depth > 0 ? (
                      firstOptionList?.map((option) => (
                        <li
                          key={option.po_uid}
                          onClick={() => setSelectedFirstOption(option)}
                        >
                          {option.po_poptionname}
                        </li>
                      ))
                    ) : (
                      <li
                        onClick={() =>
                          setSelectedFirstOption({
                            po_poptionname: detail.title,
                            po_poptionprice: detail.discount_price,
                            po_uid: 0,
                          })
                        }
                      >
                        {detail.title}
                      </li>
                    )}
                    {detail?.relation_product && (
                      <div
                        className={`${styles.adOptionWrap} ${
                          isMobile ? styles.isMobile : ''
                        }`}
                        onClick={() => {
                          if (detail?.relation_product?.id)
                            navigate(
                              `/productDetail/${detail?.relation_product?.id}`,
                            );
                        }}
                      >
                        <div className={styles.imgWrap}>
                          <img src={adProduct} alt="product" />
                        </div>
                        <div className={styles.adContentWrap}>
                          <div className={styles.adNameWrap}>
                            <span>청담보감 다이어트 타블렛</span>
                          </div>
                          <div className={styles.directWrap}>
                            <span>
                              이 제품은 어때요?{isDesktop && <br />} 비대면 진료
                              받으러 가기
                            </span>
                            <img src={adDirect} alt="direct" />
                          </div>
                        </div>
                      </div>
                    )}
                  </ul>
                )}
              </div>
            </div>
          )}
          {detail &&
            !prescriptionProgramList.includes(detail.product_id) &&
            detail.option_depth > 1 && (
              <div className={styles.option}>
                <span>필수 옵션</span>
                <div
                  className={styles.optionBox}
                  onClick={() => {
                    if (!selectedFirstOption) {
                      alert('첫 번째 옵션을 선택해주세요.');
                      setFirstOptionOpen(true);
                      return;
                    }
                    setSecondOptionOpen((prev) => !prev);
                  }}
                >
                  <span>옵션을 선택해주세요.</span>
                  <img
                    src={bottomArrow}
                    alt="bottomArrow"
                    style={{
                      transform: secondOptionOpen ? 'rotate(180deg)' : 'none',
                    }}
                  />
                  {secondOptionOpen && (
                    <ul className={styles.optionList}>
                      {secondOptionList?.map((option) => (
                        <li
                          key={option.po_uid}
                          onClick={() => setSelectedSecondOption(option)}
                        >
                          {option.po_poptionname}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
          {/* 옵션 */}
        </div>
        {selectedAllItems.length > 0 && (
          <ul
            className={`${styles.selectedOptionListWrap} ${
              isMobile ? styles.isMobile : ''
            }`}
          >
            {selectedAllItems.map((option, idx) => (
              <li key={idx}>
                <div className={!isDesktop ? styles.isMobile : ''}>
                  <span className={styles.optionName}>
                    {`${option.first?.po_poptionname}`}
                    {option.second && ` / ${option.second?.po_poptionname}`}
                    {option.third && ` / ${option.third?.po_poptionname}`}
                  </span>
                  <div
                    className={`${styles.counter} ${
                      programOption.includes(option.first?.po_uid)
                        ? styles.noCounter
                        : ''
                    }`}
                  >
                    <div
                      className={styles.minus}
                      onClick={() => {
                        if (programOption.includes(option.first?.po_uid))
                          return;
                        handleCountChange(idx, false);
                      }}
                    >
                      <img src={minus} alt="minus" />
                    </div>
                    <div className={styles.count}>{option.count}</div>
                    <div
                      className={styles.plus}
                      onClick={() => {
                        if (programOption.includes(option.first?.po_uid))
                          return;
                        handleCountChange(idx, true);
                      }}
                    >
                      <img src={plus} alt="plus" />
                    </div>
                  </div>
                  <div className={styles.editBox}>
                    <div className={styles.optionPrice}>
                      <span>
                        {option.third && option.third.po_poption_supplyprice
                          ? option.third.po_poption_supplyprice?.toLocaleString() ||
                            ''
                          : option.second &&
                            option.second.po_poption_supplyprice
                          ? option.second.po_poption_supplyprice?.toLocaleString() ||
                            ''
                          : (option.first.po_poption_supplyprice &&
                              option.first.po_poption_supplyprice!.toLocaleString()) ||
                            ''}
                      </span>
                      <span>
                        {option.third
                          ? option.third.po_poptionprice?.toLocaleString()
                          : option.second
                          ? option.second.po_poptionprice?.toLocaleString()
                          : option.first.po_poptionprice!.toLocaleString()}
                      </span>
                    </div>
                    <img
                      src={closeBtn}
                      alt="closeBtn"
                      onClick={() => handleSelectedItemDelete(idx)}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
        {detail && !prescriptionProgramList.includes(detail.product_id) && (
          <div
            className={`${styles.totalPriceWrap} ${
              isMobile ? styles.isMobile : ''
            }`}
          >
            <span className={styles.head}>총 금액</span>
            <span className={styles.totalPrice}>
              <span>{allItemsPrice.toLocaleString()}원</span>
              <span>{allItemsCount !== 0 && `(${allItemsCount}개)`}</span>
            </span>
          </div>
        )}
        <div className={styles.btnsWrap}>
          <div className={styles.pickBtn} onClick={handlePick}>
            <img
              src={detail && detail.is_like == 0 ? heart : redHeart}
              alt="heart"
            />
          </div>
          {!prescriptionProgramList.includes(detail?.product_id) &&
            detail?.product_id !== 136 && (
              <div className={styles.basketBtn} onClick={handleBasket}>
                <img src={basket} alt="basket" />
                <span>장바구니</span>
              </div>
            )}
          <div
            className={`${styles.buyBtn} ${
              prescriptionProgramList.includes(detail?.product_id)
                ? styles.noBasketBuyBtn
                : ''
            }`}
            onClick={handleBuy}
          >
            구매하기
          </div>
        </div>
        <div
          className={`${styles.kakaoPlusBannerWrap} ${
            isMobile ? styles.isMobile : ''
          }`}
        >
          {isDesktop && (
            <img
              onClick={() => {
                window.open('https://pf.kakao.com/_xaxbskb');
              }}
              src={kakaoPlusPc}
              alt="kakaoPlus"
            />
          )}
          {isMobile && (
            <div
              onClick={() => {
                window.open('https://pf.kakao.com/_xaxbskb');
              }}
            >
              <div className={styles.leftWrap}>
                <span className={styles.hitText}>온닥 플러스친구</span>
                <br />
                <span>추가 시 </span>
                <span className={styles.hitText}>3,000원 쿠폰</span>
                <div className={styles.directWrap}>
                  <span>바로가기</span>
                  <img src={kakaoDirect} alt="direct" />
                </div>
              </div>
              <img src={kakaoPlusMobile} alt="kakaoPlus" />
            </div>
          )}
        </div>
        {/* <section className={styles.challengeSection}>
					<h3>
						<span>온닥 챌린지</span>
						{!isDesktop && <span className={styles.onGoing}>챌린지 진행중</span>}
					</h3>
					<ul className={styles.challengeList}>
						{getChallengeData()
							.filter((challenge, idx) => idx == 0)
							.map((challenge, idx) => (
								<li key={idx}>
									<div className={styles.challengeThumbnailWrap}>
										<img src={challenge.image} alt='챌린지' />
									</div>
									<div className={styles.challengeDetailWrap}>
										<h4>
											<span className={styles.challengeTitle}>
												{challenge.title}
											</span>
											{isDesktop && (
												<span className={styles.onGoing}>
													챌린지 진행중
												</span>
											)}
										</h4>
										<p>혜택: 매일 3달 동안 90%달성 시, 비움환 1박스 증정</p>
										{!isDesktop && <button>자세히보기→</button>}
									</div>
									{isDesktop && <button>자세히 보기</button>}
								</li>
							))}
					</ul>
				</section> */}
        {detail?.is_prescription == 1 && (
          <div
            className={`${styles.bannersWrap} ${
              !isDesktop ? styles.isMobile : ''
            }`}
          >
            {/* <div className={styles.kakaoEventBanner}>
          <span>플러스친구 등록 3000원 쿠폰 💌</span>
        </div> */}
            <div
              className={`${styles.nonFaceProcess} ${
                isMobile ? styles.isMobile : ''
              }`}
            >
              <div
                className={styles.banner}
                onClick={() => setNonfaceProcessOpen((prev) => !prev)}
              >
                <span>비대면 처방은 어떻게 받나요??</span>
                <img
                  src={bottomArrow2}
                  alt="bottomArrow"
                  className={nonfaceProcessOpen ? styles.isOpen : ''}
                />
              </div>
              {nonfaceProcessOpen && (
                <ul className={styles.nonfaceProcessList}>
                  <li>
                    <div>
                      <div className={styles.step}>STEP.01</div>
                      <span>문진표 작성</span>
                    </div>
                    <span>제품 선택 후 문진표 작성</span>
                  </li>
                  <li>
                    <div>
                      <div className={styles.step}>STEP.02</div>
                      <span>결제 및 접수</span>
                    </div>
                    <span>결제 완료 후 1-3일 이내 담당의사 접수</span>
                  </li>
                  <li>
                    <div>
                      <div className={styles.step}>STEP.03</div>
                      <span>전화 처방</span>
                    </div>
                    <span>담당 의사로부터 전화 진료 시작</span>
                  </li>
                  <li>
                    <div>
                      <div className={styles.step}>STEP.04</div>
                      <span>당일 발송</span>
                    </div>
                    <span>오후 12시 이내 처방 완료건 당일 발송</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}
        <LazyLoad>
          <Review
            type="first"
            allReviewData={detail?.review_score_avg}
            firstReviewData={reviewData}
            firstReviewCount={reviewCount}
            firstPhotoReviewData={photoReviewData}
            firstPhotoReviewCount={photoReviewCount}
            product={detail}
          />
        </LazyLoad>
        <ul className={styles.navigationBar}>
          <li
            className={hitNavigation == 0 ? styles.hit : ''}
            onClick={() => handleScroll(0)}
          >
            상품상세
          </li>
          <li
            className={hitNavigation == 1 ? styles.hit : ''}
            onClick={() => handleScroll(1)}
          >
            상품후기
          </li>
          <li
            className={hitNavigation == 2 ? styles.hit : ''}
            onClick={() => handleScroll(2)}
          >
            상품정보
          </li>
        </ul>
        <div ref={navigationBarLocationRef}></div>
        <div className={styles.productDetail} ref={productDetailRef}>
          {!detail?.title.includes('제로이트') && (
            <img src={brand} alt="brand" />
          )}
          <div className={styles.eventImgWrap}>
            <img
              style={{
                width: '100%',
              }}
              src={productdetailevent}
              alt="productdetailevent"
            />
            <div
              className={`${styles.eventBtnWrap} ${
                isMobile ? styles.isMobile : ''
              }`}
            >
              <a href="/event">
                <img src={productdetaileventBtn} alt="eventBtn" />
              </a>
            </div>
          </div>
          {detail && detail.title.includes('청담보감 다이어트') && (
            <div
              className={`${styles.solution} ${
                isMobile ? styles.mobileSolution : styles.pcSolution
              }`}
            >
              <span>비대면 진료 서비스 온닥</span>
              <p>
                청담보감의 체계적인
                <br />
                건강한 솔루션
              </p>
              <p>
                비대면 진료 서비스로
                <br />
                처방받은 환자분들의 후기를 확인해보세요!
              </p>
              <div className={styles.reviewSliderWrap}>
                <span>
                  청담보감의 체계적인{isMobile ? <br /> : ' '}
                  다이어트 성공 사례
                </span>
                <div className={styles.reviewSlider}>
                  {!loginCheck() && <ReviewFilter />}
                  <Swiper
                    slidesPerView={isDesktop ? 2.2 : 1}
                    scrollbar={{
                      draggable: true,
                      dragSize: 170,
                    }}
                    breakpoints={{
                      700: {
                        // slidesPerView: 2.2,
                        scrollbar: { dragSize: 259 },
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review01} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            체지방 감량효과도 엄청나고 식욕억제 효과도
                            어마어마합니다. 예전에 양약 먹었을때는 솔직히 효과는
                            엄청 쌔서 좋았는데 부작용처럼 약간 술취한 느낌과
                            멍해지는게 싫었는데 청담보감은 그런 증상이 하나도
                            없어서 너무 편하게 살을 뺄 수 있었습니다!!
                            <br />
                            {` `}
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>kuuOO님</span>
                            <span>40대 / 여성 / 감량체중 -12kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review02} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            식전 2시간 전에 하루 두번 먹었습니당 .전에는
                            환으로된것도 먹어봤는데 확실히 알약이 먹기 편하고
                            효과는 당연히 좋네요. 현재 61kg에서 55.3kg 잘 유지
                            중입니다. 가방에 여분 항상 들고다녀 그리고 청담보감
                            먹을때는 물을 많이 드세요. 이것저것 해봐도 살 못
                            빼셨던 분들은 한번 드셔보세요~
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>pwjOO님</span>
                            <span>20대 / 여성 / 감량체중 -7kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review03} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            그동안 안먹어본 다이어트 제품이 없는데 이건 효과가
                            무조건 바로 나타나요 일반적인 다이어트 약 처럼 몸이
                            엄청 힘들지도 않고 식욕조절도 잘 되고 저는 운동까지
                            병행하니 단기간에 6키로 넘게 뺐네요!! 앞으로 계속
                            관리하면서 먹을 생각입니다
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>dolOO님</span>
                            <span>30대 / 여성 / 감량체중 -6kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review04} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            원래는 제가 먹으려고했는데 맨날 퇴근하고 늦은 시간에
                            폭식하고 속이 불편하다고 하는 남편 때문에 제
                            청담보감을 강제로 먹이기 시작했는데 ...요즘 야식을
                            완전히 끊었어요 짱 신기... 아예 입맛이 없다고 물만
                            마시네요 ㅋㅋ 뱃살도 좀 빠진 것 같고 대박입니다 이거
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>cheOO님</span>
                            <span>30대 / 남성 / 감량체중 -5kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={styles.slideWrap}>
                        <img src={review05} alt="reviewImage" />
                        <div className={styles.reviewInfo}>
                          <p className={styles.content}>
                            크게 운동한건 없지만 청담보감 먹으면 식욕조절이
                            잘되서 밤에 그렇게 끊기 힘들었던 야식을 끊었어요.
                            살도 쭉쭉 빠지고 급속 다이어트 완전 성공했네요.
                            청담보감 다이어트 타블렛은 확실히 요요가 없는 것
                            같아서 좋아요~!!!
                          </p>
                          <div className={styles.userInfoWrap}>
                            <span>dlgOO님</span>
                            <span>30대 / 여성 / 감량체중 -6kg</span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          )}
          {detail && (detail.product_id === 46 || detail.product_id === 90) && (
            <div className={styles.globalPayEventWrap}>
              <img
                style={{
                  width: '100%',
                }}
                src={globalPayEvent}
                alt={globalPayEvent}
              />
              <div className={styles.globalPayEventBtnWrap1}>
                <Link
                  to="#"
                  onClick={() => {
                    window.location.href =
                      'https://paypal.me/dietdr?country.x=KR&locale.x=ko_KR';
                  }}
                >
                  <img src={globalPayEventBtn1} alt="globalPayEventBtn1" />
                </Link>
              </div>
              <div className={styles.globalPayEventBtnWrap2}>
                <Link to="#" onClick={connectToChannelIO}>
                  <img src={globalPayEventBtn2} alt="globalPayEventBtn2" />
                </Link>
              </div>
            </div>
          )}
          {detail && detail.product_id === 105 && (
            <div className={styles.globalPayEventWrap}>
              <img
                style={{
                  width: '100%',
                }}
                src={globalpay2}
                alt={globalpay2}
              />
              <div className={styles.globalPayBtn2}>
                <Link
                  to="#"
                  onClick={() => {
                    window.location.href =
                      'https://paypal.me/dietdr?country.x=KR&locale.x=ko_KR';
                  }}
                >
                  <img src={globalPayBtn2} alt="globalPayBtn2" />
                </Link>
              </div>
            </div>
          )}
          {detail?.title.includes('샐러드') && (
            <>
              <div className={styles.saladProductDetailWrap}>
                <img src={saladBack1} alt="background" />
                <div className={styles.videoWrap}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad1.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap2}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad2.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap3}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad3.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap4}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad4.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap5}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad5.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap6}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad6.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap7}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad7.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap8}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad8.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.saladProductDetailWrap}>
                <img src={saladBack2} alt="background" />
                <div className={styles.videoWrap21}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad21.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap22}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad22.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap23}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad23.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap24}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad24.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap25}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad25.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.saladProductDetailWrap}>
                <img src={saladBack3} alt="background" />
                <div className={styles.videoWrap31}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad31.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap32}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={
                        navigator.userAgent.match(/iPhone/i)
                          ? require('../../assets/video/salad32.mov')
                          : require('../../assets/video/salad32.mp4')
                      }
                      // src={require('../../assets/video/salad32.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap33}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/salad33.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </>
          )}
          {detail?.title.includes('비움환') && (
            <>
              <div className={styles.biumProductDetailWrap}>
                <img src={biumBack1} alt="background" />
                <div className={styles.videoWrap}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/bium1.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap2}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/bium2.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap3}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/bium3.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap4}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/bium4.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.biumProductDetailWrap}>
                <img src={biumBack2} alt="background" />
              </div>
            </>
          )}
          {detail?.title.includes('제로이트') && (
            <>
              <div className={styles.zeroProductDetailWrap}>
                <img src={zeroBack1} alt="zeroBackground" />
                <div className={styles.videoWrap}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={
                        navigator.userAgent.match(/iPhone/i)
                          ? require('../../assets/video/zero1.mov')
                          : require('../../assets/video/zero1.mp4')
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap2}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/zero2.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap3}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/zero3.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap4}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/zero4.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap5}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/zero5.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.zeroProductDetailWrap}>
                <img src={zeroBack2} alt="zeroBackground" />
                <div className={styles.videoWrap21}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/zero21.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap22}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/zero22.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.zeroProductDetailWrap}>
                <img src={zeroBack3} alt="zeroBackground" />
              </div>
            </>
          )}
          {detail?.title.includes('CCA') && (
            <>
              <div className={styles.ccaProductDetailWrap}>
                <img src={ccaBack1} alt="ccaBackground" />
                <div className={styles.videoWrap}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca1.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap2}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca2.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap3}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca3.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap4}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca4.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap5}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca5.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap6}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca6.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap7}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca7.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.ccaProductDetailWrap2}>
                <img src={ccaBack2} alt="ccaBackground" />
                <div className={styles.videoWrap21}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca21.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap22}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca22.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap23}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca23.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap24}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca24.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.ccaProductDetailWrap3}>
                <img src={ccaBack3} alt="ccaBackground" />
                <div className={styles.videoWrap31}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca31.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap32}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/cca32.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </>
          )}
          {detail?.title.includes('다이어트 프로그램') && (
            <>
              <div className={styles.dietDetailWrap}>
                <img
                  className={styles.backImg}
                  src={dietBack1}
                  alt="background"
                />
                <div className={styles.videoWrap}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/diet1.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap2}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/diet2.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap3}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/diet3.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap4}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/diet4.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap5}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/diet5.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div className={styles.videoWrap6}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/diet6.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.dietDetailWrap}>
                <img
                  className={styles.backImg}
                  src={dietBack2}
                  alt="background"
                />
                <div className={styles.videoWrap21}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/diet21.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.dietDetailWrap}>
                <img
                  className={styles.backImg}
                  src={dietBack3}
                  alt="background"
                />
                <div className={styles.videoWrap31}>
                  <video
                    onContextMenu={(e) => e.preventDefault()}
                    muted
                    autoPlay
                    playsInline
                    loop
                    width="100%"
                    height="100%"
                  >
                    <source
                      src={require('../../assets/video/diet31.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className={styles.appBtnWrap}>
                <img
                  onClick={() => {
                    window.location.href =
                      'https://apps.apple.com/kr/app/id647595576';
                  }}
                  src={appleBtn}
                  alt="appleBtn"
                />
                <img
                  onClick={() => {
                    window.location.href =
                      'https://play.google.com/store/apps/details?id=kr.co.ondoc';
                  }}
                  src={googleBtn}
                  alt="googleBtn"
                />
              </div>
            </>
          )}
          <LazyLoad onContentVisible={() => handleAddEvent()}>
            <div
              style={{ fontSize: '0px' }}
              dangerouslySetInnerHTML={{
                __html: detail ? detail.content.replace(/\\/gi, '') : '',
              }}
            ></div>
          </LazyLoad>
        </div>
        <div ref={reviewRef}></div>
        <LazyLoad>
          <Review
            allReviewData={detail?.review_score_avg}
            firstReviewData={reviewData}
            firstReviewCount={reviewCount}
            firstPhotoReviewData={photoReviewData}
            firstPhotoReviewCount={photoReviewCount}
            product={detail}
          />
        </LazyLoad>
        <div className={styles.productInfoArea} ref={productInfoRef}>
          <h3>상품 정보</h3>
          <div className={styles.deliveryInfo}>
            <h4>배송안내</h4>
            <ul className={styles.deliveryInfoList}>
              <li>
                <span>· 택배사</span>
                <span>{detail?.delivery_price_info.delivery_company}</span>
              </li>
              <li>
                <span>· 배송비</span>
                <span>
                  {detail?.delivery_cost.toLocaleString()}원 *
                  {detail?.delivery_case_price ? (
                    <>
                      {detail?.delivery_case_price.toLocaleString()}원 이상
                      무료배송
                    </>
                  ) : (
                    ''
                  )}
                  <br />
                  (도서산간 지역에 따라 추가 배송비가 발생할 수 있습니다.)
                </span>
              </li>
              <li>
                <span>· 배송기간</span>
                <span>
                  {/* 평균적으로 주문일로부터 평일 기준{' '}
                  {detail?.delivery_price_info.delivery_date} 소요됩니다.
                   */}
                  비대면 진료 후 배송받으시는 데까지 주말 제외 3일-4일
                  소요됩니다.
                  <br />
                  주말/ 공휴일이 포함되거나 주문폭주 및 택배사 사정 등으로 인한
                  경우 배송이 지연될 수 있습니다.
                </span>
              </li>
            </ul>
          </div>
          <div className={styles.exchangeInfo}>
            <h4>교환/반품안내</h4>
            <span>
              미개봉 제품에 한하여, 제품 수령일로부터 7일 이내 교환, 환불이
              가능합니다.
            </span>
            {/* <span>{detail?.delivery_price_info.delivery_return_addr}</span> */}
          </div>
          <div className={styles.globalLegitScriptWrap}>
            <img
              className={styles.legitLogo}
              src="	https://static.legitscript.com/seals/14145209.png"
              alt="legit"
            />
          </div>
        </div>
        <Footer />
        {isFixed && (
          <div
            className={`${styles.fixedContents} ${
              detail?.relation_product ? styles.adExist : ''
            }`}
            // style={{
            //   top: detail?.relation_product
            //     ? '100px !important'
            //     : '48px !important',
            // }}
          >
            {isDesktop && (
              <>
                <div className={styles.productInfoSection}>
                  <div className={styles.left}>
                    <div className={styles.productImg}>
                      {detail && (
                        <img src={detail?.list_image} alt="thumbnail" />
                      )}
                    </div>
                    <div className={styles.productInfo}>
                      <span>{detail?.title}</span>
                      <div className={styles.priceInfo}>
                        <span className={styles.discountPrice}>
                          {detail?.discount_price.toLocaleString()}원
                        </span>
                        {detail?.price !== 0 &&
                          detail?.price !== detail?.discount_price && (
                            <span className={styles.price}>
                              {detail?.price.toLocaleString()}원
                            </span>
                          )}
                      </div>
                    </div>
                  </div>
                  {fixedFormOpen ? (
                    <div
                      className={styles.formCloseBtn}
                      onClick={() => setFixedFormOpen(false)}
                    >
                      닫기
                    </div>
                  ) : (
                    <div
                      className={styles.formOpenBtn}
                      onClick={() => {
                        if (
                          prescriptionProgramList.includes(detail?.product_id)
                        )
                          handleBuy();
                        else setFixedFormOpen(true);
                      }}
                    >
                      바로구매
                    </div>
                  )}
                </div>
                {fixedFormOpen && (
                  <div className={styles.optionSelectSection}>
                    <div className={styles.left}>
                      <ul className={styles.optionSelectList}>
                        {detail &&
                          !prescriptionProgramList.includes(
                            detail.product_id,
                          ) && (
                            <li>
                              <span>필수 옵션</span>
                              <div
                                className={styles.optionBox}
                                onClick={() =>
                                  setFirstOptionOpen((prev) => !prev)
                                }
                              >
                                <span
                                  style={{
                                    color: selectedFirstOption
                                      ? '#131C31'
                                      : '#abb1bb',
                                  }}
                                >
                                  {selectedFirstOption
                                    ? selectedFirstOption.po_poptionname
                                    : '옵션을 선택해주세요.'}
                                </span>
                                <img src={bottomArrow} alt="arrow" />
                                {firstOptionOpen && (
                                  <ul className={styles.optionList}>
                                    {detail.option_depth > 0 ? (
                                      firstOptionList?.map((option) => (
                                        <li
                                          key={option.po_uid}
                                          onClick={() =>
                                            setSelectedFirstOption(option)
                                          }
                                        >
                                          {option.po_poptionname}
                                        </li>
                                      ))
                                    ) : (
                                      <li
                                        onClick={() =>
                                          setSelectedFirstOption({
                                            po_poptionname: detail.title,
                                            po_poptionprice:
                                              detail.discount_price,
                                            po_uid: 0,
                                          })
                                        }
                                      >
                                        {detail.title}
                                      </li>
                                    )}
                                  </ul>
                                )}
                              </div>
                            </li>
                          )}
                        {detail &&
                          !prescriptionProgramList.includes(
                            detail.product_id,
                          ) &&
                          detail.option_depth > 1 && (
                            <li>
                              <span>필수 옵션</span>
                              <div className={styles.optionBox}>
                                <span
                                  style={{
                                    color: selectedSecondOption
                                      ? '#131C31'
                                      : '#abb1bb',
                                  }}
                                >
                                  {selectedSecondOption
                                    ? selectedSecondOption.po_poptionname
                                    : '옵션을 선택해주세요.'}
                                </span>
                                <img src={bottomArrow} alt="arrow" />
                                {secondOptionOpen && (
                                  <ul className={styles.optionList}>
                                    {secondOptionList?.map((option) => (
                                      <li
                                        key={option.po_uid}
                                        onClick={() =>
                                          setSelectedSecondOption(option)
                                        }
                                      >
                                        {option.po_poptionname}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </li>
                          )}
                      </ul>
                      <ul className={styles.selectedOptionList}>
                        {selectedAllItems.map((option, idx) => (
                          <li>
                            <span className={styles.productName}>
                              {`${option.first?.po_poptionname}`}
                              {option.second &&
                                ` / ${option.second?.po_poptionname}`}
                              {option.third &&
                                ` / ${option.third?.po_poptionname}`}
                            </span>
                            <div className={styles.counter}>
                              <div
                                className={styles.minus}
                                onClick={() => handleCountChange(idx, false)}
                              >
                                <img src={minus} alt="minus" />
                              </div>
                              <div className={styles.number}>
                                <span>{option.count}</span>
                              </div>
                              <div
                                className={styles.plus}
                                onClick={() => handleCountChange(idx, true)}
                              >
                                <img src={plus} alt="plus" />
                              </div>
                            </div>
                            <div className={styles.price}>
                              <div className={styles.detail}>
                                <span>
                                  {option.third &&
                                  option.third.po_poption_supplyprice
                                    ? option.third.po_poption_supplyprice?.toLocaleString() +
                                        '원' || ''
                                    : option.second &&
                                      option.second.po_poption_supplyprice
                                    ? option.second.po_poption_supplyprice?.toLocaleString() +
                                        '원' || ''
                                    : (option.first.po_poption_supplyprice &&
                                        option.first.po_poption_supplyprice!.toLocaleString() +
                                          '원') ||
                                      ''}
                                </span>
                                <span>
                                  {option.third
                                    ? option.third.po_poptionprice?.toLocaleString()
                                    : option.second
                                    ? option.second.po_poptionprice?.toLocaleString()
                                    : option.first.po_poptionprice!.toLocaleString()}
                                  원
                                </span>
                              </div>
                              <img
                                src={closeBtn}
                                alt="closeBtn"
                                onClick={() => handleSelectedItemDelete(idx)}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                    {detail &&
                      !prescriptionProgramList.includes(detail.product_id) && (
                        <div className={styles.centerLine}></div>
                      )}
                    <div className={styles.right}>
                      {detail &&
                        !prescriptionProgramList.includes(
                          detail.product_id,
                        ) && (
                          <div className={styles.totalPrice}>
                            <span>총 금액</span>
                            <div className={styles.price}>
                              <span>{allItemsPrice.toLocaleString()}원</span>
                              <span>({allItemsCount}개)</span>
                            </div>
                          </div>
                        )}
                      <div className={styles.fixedFormBtnsWrap}>
                        <div className={styles.buyBtn} onClick={handleBuy}>
                          바로구매
                        </div>
                        <div className={styles.pickBtn} onClick={handlePick}>
                          <img
                            src={
                              detail && detail.is_like == 0 ? heart : redHeart
                            }
                            alt="heart"
                          />
                          <span>찜하기</span>
                        </div>
                        {!prescriptionProgramList.includes(
                          detail?.product_id,
                        ) &&
                          detail?.product_id !== 136 && (
                            <div
                              className={styles.basketBtn}
                              onClick={handleBasket}
                            >
                              <img src={basket} alt="basket" />
                              <span>장바구니</span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <ul className={styles.fixedNavigationBar}>
              <li
                className={hitNavigation == 0 ? styles.hit : ''}
                onClick={() => handleScroll(0)}
              >
                상품상세
              </li>
              <li
                className={hitNavigation == 1 ? styles.hit : ''}
                onClick={() => handleScroll(1)}
              >
                상품후기
              </li>
              <li
                className={hitNavigation == 2 ? styles.hit : ''}
                onClick={() => handleScroll(2)}
              >
                상품정보
              </li>
            </ul>
          </div>
        )}
        {isMobile && (
          <div className={styles.mobileBottomTab}>
            <div
              className={`${styles.contentArea} ${
                fixedFormOpen ? styles.isOpen : ''
              }`}
            >
              <div
                className={styles.closeBtnArea}
                onClick={() => setFixedFormOpen(false)}
              >
                <img src={detailFixFormClose} alt="closeBtnImage" />
              </div>
              <div className={styles.optionSelect}>
                {detail &&
                  !prescriptionProgramList.includes(detail.product_id) && (
                    <div className={styles.option}>
                      <span>필수 옵션</span>
                      <div
                        className={styles.optionBox}
                        onClick={() => setFirstOptionOpen((prev) => !prev)}
                      >
                        <span
                          style={{
                            color: selectedFirstOption ? '#131C31' : '#abb1bb',
                          }}
                        >
                          {selectedFirstOption
                            ? selectedFirstOption.po_poptionname
                            : '옵션을 선택해주세요.'}
                        </span>
                        <img
                          src={bottomArrow}
                          alt="arrow"
                          style={{
                            transform: firstOptionOpen ? 'rotate(180deg)' : '',
                          }}
                        />
                      </div>
                      {firstOptionOpen && (
                        <ul className={styles.optionList}>
                          {detail.option_depth > 0 ? (
                            firstOptionList?.map((option) => (
                              <li
                                key={option.po_uid}
                                onClick={() => setSelectedFirstOption(option)}
                              >
                                {option.po_poptionname}
                              </li>
                            ))
                          ) : (
                            <li
                              onClick={() =>
                                setSelectedFirstOption({
                                  po_poptionname: detail.title,
                                  po_poptionprice: detail.discount_price,
                                  po_uid: 0,
                                })
                              }
                            >
                              {detail.title}
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  )}
                {detail &&
                  !prescriptionProgramList.includes(detail.product_id) &&
                  detail.option_depth > 1 && (
                    <div className={styles.option}>
                      <span>필수 옵션</span>
                      <div
                        className={styles.optionBox}
                        onClick={() => {
                          if (!selectedFirstOption) {
                            alert('첫 번째 옵션을 선택해주세요.');
                            setFirstOptionOpen(true);
                            return;
                          }
                          setSecondOptionOpen((prev) => !prev);
                        }}
                      >
                        <span
                          style={{
                            color: selectedSecondOption ? '#131C31' : '#abb1bb',
                          }}
                        >
                          {selectedSecondOption
                            ? selectedSecondOption.po_poptionname
                            : '옵션을 선택해주세요.'}
                        </span>
                        <img
                          src={bottomArrow}
                          alt="arrow"
                          style={{
                            transform: secondOptionOpen ? 'rotate(180deg)' : '',
                          }}
                        />
                      </div>
                      {secondOptionOpen && (
                        <ul className={styles.optionList}>
                          {secondOptionList?.map((option) => (
                            <li
                              key={option.po_uid}
                              onClick={() => setSelectedSecondOption(option)}
                            >
                              {option.po_poptionname}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
              </div>
              <ul className={styles.selectedOptionList}>
                {selectedAllItems.map((option, idx) => (
                  <li className={styles.selectedOption} key={idx}>
                    <div className={styles.top}>
                      <span>
                        {option.first.po_poptionname}
                        {option.second && ` / ${option.second?.po_poptionname}`}
                        {option.third && ` / ${option.third?.po_poptionname}`}
                      </span>
                      <img
                        src={closeBtn}
                        alt="closeBtn"
                        onClick={() => handleSelectedItemDelete(idx)}
                      />
                    </div>
                    <div className={styles.bottom}>
                      <div
                        className={`${styles.counterBox} ${
                          programOption.includes(option.first?.po_uid)
                            ? styles.noCounterBox
                            : ''
                        }`}
                      >
                        <div
                          className={styles.minus}
                          onClick={() => {
                            if (programOption.includes(option.first?.po_uid))
                              return;
                            handleCountChange(idx, false);
                          }}
                        >
                          <img src={minus} alt="minus" />
                        </div>
                        <div className={styles.count}>{option.count}</div>
                        <div
                          className={styles.plus}
                          onClick={() => {
                            if (programOption.includes(option.first?.po_uid))
                              return;
                            handleCountChange(idx, true);
                          }}
                        >
                          <img src={plus} alt="plus" />
                        </div>
                      </div>
                      <div className={styles.optionPrice}>
                        <span>
                          {option.third && option.third.po_poption_supplyprice
                            ? option.third.po_poption_supplyprice?.toLocaleString() ||
                              ''
                            : option.second &&
                              option.second.po_poption_supplyprice
                            ? option.second.po_poption_supplyprice?.toLocaleString() ||
                              ''
                            : (option.first.po_poption_supplyprice &&
                                option.first.po_poption_supplyprice!.toLocaleString()) ||
                              ''}
                        </span>
                        <span>
                          {option.third
                            ? option.third.po_poptionprice?.toLocaleString()
                            : option.second
                            ? option.second.po_poptionprice?.toLocaleString()
                            : option.first.po_poptionprice!.toLocaleString()}
                          원
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {detail &&
                !prescriptionProgramList.includes(detail.product_id) && (
                  <div className={styles.totalPriceInfoWrap}>
                    <span>총 금액</span>
                    <div className={styles.totalPrice}>
                      <span>{allItemsPrice.toLocaleString()}원</span>
                      <span>
                        {allItemsCount !== 0 && `(${allItemsCount}개)`}
                      </span>
                    </div>
                  </div>
                )}
            </div>
            {!fixedFormOpen && (
              <div className={styles.notOpened}>
                <div className={styles.pickBtn} onClick={handlePick}>
                  <img
                    src={detail && detail.is_like == 0 ? heart : redHeart}
                    alt="heart"
                  />
                </div>
                <div
                  className={styles.openBtn}
                  onClick={() => {
                    if (
                      detail &&
                      !prescriptionProgramList.includes(detail.product_id)
                    )
                      setFixedFormOpen(true);
                    else handleBuy();
                  }}
                >
                  바로구매
                </div>
              </div>
            )}
            {fixedFormOpen && (
              <div
                className={`${styles.opened} ${
                  detail?.product_id === 136 ? styles.buyOpend : ''
                }`}
              >
                {detail &&
                  !prescriptionProgramList.includes(detail.product_id) &&
                  detail?.product_id !== 136 && (
                    <div className={styles.basketBtn} onClick={handleBasket}>
                      장바구니 담기
                    </div>
                  )}
                <div className={styles.buyBtn} onClick={handleBuy}>
                  바로구매
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/* {biumhwanOpen && <BiumhwanModal closer={setBiumhwanOpen} />} */}
      {gambiteaOpen && <GambiteaModal closer={setGambiteaOpen} />}
      {adModalOpen && detail?.relation_product && (
        <ADLink
          productId={detail.relation_product.id}
          price={detail.relation_product.price}
          discountedPrice={detail.relation_product.sale_price}
          closer={setAdModalOpen}
        />
      )}
      {prescriptionLoginOpen && (
        <PrescriptionLoginModal closer={setPrescriptionLoginOpen} />
      )}
      {nonMemberOpen && (
        <NonMemberModal
          closer={setNonMemberOpen}
          onContinueBuy={handleBuyContinue}
        />
      )}
      {programNonMemberOpen && (
        <ProgramNonMemberModal closer={setProgramNonMemberOpen} />
      )}
    </>
  );
}
export default ProductDetail;
